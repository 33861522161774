@import "../abstracts/variables";

.createAdUpAccountToggle {
  font-family: var(--main-font-primary);

  &-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3.2rem;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    background-color: #fff;
    border: solid #c3d3d4 1px;
    border-radius: var(--button-border-radius);
    padding: 1rem;
    box-shadow: $normal-box-shadow;

    img {
      /* margin-top: 0.5rem; */
      cursor: pointer;
    }

    input {
      height: 1.8rem !important;
      width: 1.8rem !important;
      cursor: pointer !important;
    }

    /*  input {
      height: 1.8rem !important;
      width: 1.8rem !important;
      cursor: pointer !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      outline: 0.5px solid $dark-icon-color;
      border-radius: 0.2rem;
      background-color: white;
      -webkit-box-shadow: 0px 0px 4px 1px #076fd8;
      box-shadow: 0px 0px 4px 1px #076fd8;

      &:checked {
        background: white url("../../assets/svg/tick.svg") no-repeat;
        background-size: 1.8rem 1.8rem;
        background-position: 50% 50%;
      }
    } */

    &-text {
      margin-bottom: 0.12rem;
      font-weight: 500 !important;
      color: $main-black;
    }
  }
}

.createAdUpAccount {
  height: 7.6rem;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  padding-left: 3rem;

  &__left {
    height: 3.2rem;
    width: 3.2rem;
    margin-right: 0.7rem;
    margin-bottom: 0.4rem;
    img {
      height: 100%;
      width: 100%;
    }
  }

  &__right {
    font-family: var(--main-font-primary);
    padding-left: 1.5rem;
    span {
      margin-bottom: 0;
      margin-top: 0;
      bottom: 2rem;
      font-size: 0.9rem;
      font-weight: 600 !important;
      color: $placeholder-color;
      line-height: 0.4rem;
    }

    a {
      margin-bottom: 0;
      margin-top: 0.2rem;
      font-size: 1.2rem;
      line-height: 0.9rem;
      margin-right: 2.2rem;
      color: $main-blue;
      cursor: pointer;
    }

    h3 {
      margin-bottom: 0;
      margin-top: 0.2rem;
      font-size: 1.5rem;
      line-height: 0.9rem;
      font-weight: 800 !important;
      color: $main-black;
    }
  }
}
