@import "../../../abstracts/variables";

.layout2Desktop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  &__container1 {
    width: 67%;
    &--product {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      &-image {
        width: 49%;
      }

      &-detail {
        width: 49%;

        .productInfoSC {
          margin-top: 0;
          padding: 0;
        }
      }
    }

    &--review {
      width: 100%;
      height: 60vh;
      background-color: rgb(231, 231, 231);
      border-radius: 0.7rem;
      padding: 1.5rem;

      font-family: var(--main-font-primary);
      color: var(--main-dark-font-color);
      font-size: 1.75rem;
      font-weight: 500;
      margin-bottom: 1.75rem;
    }
  }

  &__container2 {
    width: 30%;

    &--top {
    }
    &--bottom {
    }
  }
}
