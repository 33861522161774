.fastCheckoutMobileExtraLayout2-right {
  margin-bottom: 2rem;
  #paymentMethodsRef001 {
    background-color: #fff;
    padding: 1.4rem 1.82em;
    border-radius: 0.7rem;
  }

  .headingExpandable {
    border-top: none;
    padding: 0;
  }
}
/* .fastCheckoutDesktopExtraLayout2-right {
  } */

.fastCheckoutMobileExtraLayout2-userDetails {
  background-color: #fff;
  padding: 0rem 1.82em;
  border-radius: 0.7rem;
  padding-bottom: 0.5rem;

  /* .shipping-details {
      margin: 0;
      padding-top: ;
    }
   */
  .billing-details {
    margin: 0;
    padding: 1.75rem 0rem;
  }
}

@media only screen and (max-width: 750px) {
  .main-right-content-holder {
    margin-top: 0rem;
  }
}
