.form-label {
  position: absolute;
  left: 1.4rem;
  top: 1.8rem;
  color: #999;
  background-color: #fff;
  z-index: 10;

  transition: top 150ms ease-in-out, font-size 50ms ease;
  transform-origin: 0;
  font-size: 1.6rem;

  padding: 0 0.4rem;
  padding-left: 0.2rem;
  margin-left: -0.5rem;
}

// .focused .form-label {
//   font-size: 9px;
// }

.form-input {
  padding: 1.6rem;
  border: solid 1px #eae7e4;
  border-radius: $border-radius;
  letter-spacing: 0.05em;
  font-size: 1.6rem;

  position: relative;
  padding: 1.2rem 0rem 0.5rem 0rem;
  width: 100%;
  outline: 0;
  border: 0;
  -webkit-appearance: none;
  transition: box-shadow 150ms ease-out;
}

.form-group {
  position: relative;
  max-width: 100%;
  box-sizing: border-box;

  .form-input {
    width: 100%;
    max-width: 100%;
  }

  & + .form-group {
    margin-top: 1.5rem;
  }
}
.phone-number-inputs.focused label {
  z-index: 10 !important;
  padding: 0 0.4rem;
  margin-left: -0.5rem;
  font-family: var(--main-font-primary) !important;
}

.form-label-phone {
  top: 0.4rem;
  position: absolute;
}

.plain-address-field {
  font-family: var(--main-font-primary) !important;

  * {
    background-color: white;
  }
}
