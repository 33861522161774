.dummyCheckoutGenerator {
  display: flex;
  width: 100%;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    padding: 0 2rem;
  }
  @media only screen and (max-width: 750px) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
  }

  &--title {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      padding: 0 2rem;
    }
    @media only screen and (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      margin-top: 0rem;
      margin-bottom: 1rem;
    }

    h2 {
      max-width: 50%;
      @media only screen and (max-width: 750px) {
        max-width: 100%;
      }
    }

    button {
      cursor: pointer;
      background-color: #00f6cd;
      -webkit-box-shadow: 0px 1px 8px -2px #2af3cc;
      box-shadow: 0px 1px 8px -2px #2af3cc;
      color: black;
      font-size: 1.6rem;
      font-family: var(--main-font-primary);
      text-align: center;
      font-weight: 700 !important;
      border-radius: $border-radius;
      padding: 1rem 2rem;
      border: none;
      text-transform: uppercase;
    }
  }

  &-btnHolder {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    @media only screen and (max-width: 750px) {
      display: flex;
    }
    button {
      cursor: pointer;
      border: solid 1px #c3d3d4;
      font-size: 1.7rem;
      font-family: var(--main-font-primary);
      text-align: center;
      font-weight: 700 !important;
      border-radius: $border-radius;
      padding: 1.3rem 0;
      width: 80%;
      @media only screen and (max-width: 750px) {
        width: 100%;
      }
    }

    button[data-is-generated-button-enabled="true"] {
      background-color: #00f6cd;
      -webkit-box-shadow: 0px 1px 8px -2px #2af3cc;
      box-shadow: 0px 1px 8px -2px #2af3cc;
      color: black;
    }
  }

  &__left {
    width: 48%;
    /* padding-left: 2rem; */
    padding-right: 0rem;
    @media only screen and (max-width: 750px) {
      padding-left: 2rem;
      padding-right: 2rem;
      width: 100%;
    }
  }

  &__right {
    padding-top: 1rem;
    width: 48%;
    padding-left: 2rem;

    /* padding-top: 6.1rem; */
    @media only screen and (max-width: 750px) {
      /*  padding-left: 2rem;
      padding-right: 2rem;
      width: 100%; */
      display: none;
    }

    &--placeholder {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      /* height: 80vh; */
      border: none;
      border-radius: $border-radius;
      margin: 0;
      padding: 0;
      border: solid 1px #c3d3d4;

      img {
        height: 10rem;
        width: 10rem;
      }

      h2 {
        margin-top: 35px;
        text-align: center;
        font-family: var(--main-font-primary);
        font-weight: 200 !important;
        font-size: 20px;
        line-height: 30px;
      }
    }

    &--iframeHolder {
      width: 100%;
      /* height: 80vh; */
      overflow: hidden !important;
      border-radius: $border-radius;
      border: solid 1px #c3d3d4;
    }

    iframe {
      // width: calc(100% + 17px);
      width: 100%;
      /* height: 100%; */
      border: none;
    }
  }
}
