@import "../abstracts/variables";

/* .root-page {
  background-color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 2rem;
  position: sticky;
  img {
    animation: pulsate 600ms alternate infinite ease;
    width: 30vw;
    @media only screen and (max-width: 750px) {
      width: 60vw;
    }
  }
  img {
    // animation: pulsate 600ms alternate infinite ease;
    max-width: 70%;
    max-height: 50%;
    margin: auto;
  }

  .column {
    flex: 1;
    padding: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  h1 {
    margin-top: 3.5rem;
    text-align: center;
    font-family: var(--main-font-primary);
    font-weight: 600 !important;
    //default font-size: 55px has been declared inline;
    margin-bottom: 0rem;
    font-size: 5.5rem;

    @media only screen and (max-width: 750px) {
      font-size: 2rem !important;
    }
  }
  p {
    text-align: center;
    font-family: var(--main-font-secondary) !important;
    font-weight: 400 !important;
    font-size: 2.2rem;
    margin: 0 !important;
    font-size: 4rem;
    //default font-size: 55px has been declared inline;
  }
  div {
    text-align: center;
    font-family: var(--main-font-secondary) !important;
    font-weight: 400 !important;
    font-size: 2.5rem;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    span {
      color: "#800080";
      cursor: "pointer";
    }
  }

  &--title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      padding: 0 2rem;
    }
    @media only screen and (max-width: 750px) {
      flex-direction: column;
      margin-top: 0rem;
      margin-bottom: 1rem;
    }

    h2 {
      max-width: 50%;
      @media only screen and (max-width: 750px) {
        max-width: 100%;
      }
    }

    button {
      cursor: pointer;
      background-color: #000000;
      -webkit-box-shadow: 0px 1px 8px -2px #000000;
      box-shadow: 0px 1px 8px -2px #000000;
      color: #fff;
      font-size: 1.6rem;
      font-family: var(--main-font-primary);
      text-align: center;
      font-weight: 700 !important;
      border-radius: $border-radius;
      padding: 1rem 2rem;
      border: none;
      align-self: center;
    }
    button[shop-slug-available="false"] {
      background-color: #d3d3d3;
      -webkit-box-shadow: 0px 1px 8px -2px #d3d3d3;
      box-shadow: 0px 1px 8px -2px #d3d3d3;
      color: black;
      cursor: not-allowed;
    }
  }
}
 */

.rootPage {
  min-height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000000;
  padding: 4rem;
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h1 {
      font-size: 4.5rem;
      margin-bottom: 0;
      font-weight: 500 !important;

      @media only screen and (max-width: 750px) {
        font-size: 3.5rem;
      }
    }

    h3 {
      margin-top: 1rem;
      margin-bottom: 0;
      font-size: 2.5rem;
      font-weight: 500 !important;
      @media only screen and (max-width: 750px) {
        font-size: 1.8rem;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 2rem;
      max-width: 80rem;

      @media only screen and (max-width: 750px) {
        font-size: 1.4rem;
        max-width: 85%;
      }

      span {
        color: $main-blue-hover;
        cursor: pointer;
        margin-top: 1rem;
        font-size: 2rem;

        @media only screen and (max-width: 750px) {
          font-size: 1.4rem;
        }
      }
    }

    span {
      margin-top: 4rem;
      font-size: 1.5rem;
      color: $input-color;
      margin-bottom: 0.5rem;

      @media only screen and (max-width: 750px) {
        font-size: 1.3rem;
        margin-top: 3.5rem;
      }
    }
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      object-fit: contain;
      width: 7rem;
      margin-left: 1.2rem;
      margin-right: 1.2rem;

      @media only screen and (max-width: 750px) {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
        width: 5rem;
      }
    }
  }

  &__bottom {
    margin-top: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @media only screen and (max-width: 750px) {
      margin-top: 7rem;
    }

    p {
      font-size: 1.2rem;
      color: $input-color;
      margin-bottom: 1rem;
    }

    &--links {
      span {
        margin-left: 1rem;
        margin-right: 1rem;
        cursor: pointer;
        color: $main-blue-hover;
      }
    }
  }
}
