.payment-received-view {
  --padding-around-view: 0.5rem;
  --button-spacing: 0rem;
  --padding-around-view-half: -1rem;

  padding: var(--padding-around-view);

  h1 {
    font-family: var(--main-font-primary);
    font-weight: bold !important;
    height: 7.8rem;
    width: 100%;
    left: 0.4rem;
    top: 7.2rem;
    text-align: center;
    margin: 0;
    padding-top: 1rem;
    margin-bottom: -4rem;
  }

  .payment-received-tick-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .payment-received-image-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }

    .payment-received-order-summary {
      width: 36rem;

      @media only screen and (max-width: 400px) {
        width: 90%;
      }
    }
  }

  h2 {
    font-family: var(--main-font-primary);
    text-align: center;
    width: 100%;
    margin: 0;
    font-weight: 400 !important;
  }
  h3 {
    font-family: var(--main-font-primary);
    height: 7.8rem;
    width: 100%;
    left: 0.4rem;
    top: 7.2rem;
    text-align: center;
    margin: 0;
    padding-top: 2rem;
    margin-bottom: -2rem;
    font-size: medium;
    font-weight: 700 !important;
  }
  h4 {
    font-family: var(--main-font-primary);
    height: 7.8rem;
    width: 100%;
    left: 0.4rem;
    top: 7.2rem;
    text-align: center;
    margin: 0;
    padding-top: 2rem;
    margin-bottom: -2rem;
    font-weight: 500 !important;
    font-size: small;

    a {
      color: $main-blue !important;
      font-weight: 600 !important;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--main-font-primary);
    font-size: 1.8rem;
    min-height: 6rem;
    width: 100%;
    margin-bottom: var(--button-spacing);
    border-radius: var(--button-border-radius);
  }

  .bottom-spacing {
    width: 100%;
  }

  .menu-button-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    a.your-account {
      color: var(--main-dark-font-color);
      background-color: rgb(255, 255, 255);
      border: none;
      box-shadow: var(--box-shadow-gray-light);
      /* max-width: 30rem; */
      width: 100%;
      align-items: left;
      justify-content: left;
      /* height: 3rem; */
      margin-bottom: 0.2rem;
      font-size: 1.5rem;
      font-weight: 500 !important;
      padding: 1em 2.8em 0.5em 2.8em;
      font-family: var(--main-font-primary);
      .animated-arrow-right {
        stroke: $nav-back-color;
      }
    }
  }

  .shop-more-button-holder {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;

    position: relative;
    bottom: 7.55rem;
    button.shop-more {
      color: white;
      background-color: black;
      border: none;
      box-shadow: var(--box-shadow-gray-light);
      max-width: 28rem;
      align-items: left;
      justify-content: left;
      padding: 1.5rem;

      b {
        top: 1rem;
        position: absolute;
      }

      p {
        position: absolute;
        font-size: 1rem;
        font-weight: 250 !important;
        top: 2.4rem;
        font-family: var(--main-font-secondary) !important;
      }
    }
  }
  button.btn-verify-phone-number {
    background-color: var(--theme-teal);
    box-shadow: var(--box-shadow-teal-light);
    border: none;
  }

  .payment-received-links {
    width: 36rem;
    background-color: $main-white;
    margin-left: auto;
    margin-right: auto;
    border-radius: $border-radius;
    border: solid 1px #e4ebec;
    box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);
    overflow: hidden;
    @media only screen and (max-width: 400px) {
      width: 90%;
    }
  }

  .verify-phone-number-segment {
    .payment-received-otp {
      //  transform: translateX(var(--padding-around-view-half));
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    padding-bottom: 15rem;
    background-color: rgba(255, 255, 255, 0.561) !important;
    -webkit-backdrop-filter: blur(1.2em) !important;
    backdrop-filter: blur(1.2em) !important;
    border-radius: 5%;
    box-shadow: var(--box-shadow-gray-light);
    p {
      color: #787878;
      font-size: 1.4rem;
      text-align: center;
      font-family: var(--main-font-secondary) !important;
    }

    .otp-inputs-holder {
      // transform: translateX(-9px);
      // display: grid;

      width: 100%;
      max-width: 32rem;
      display: flex;
      grid-template-columns: repeat(4, 21.25%);
      column-gap: 5%;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      // height: 75px;
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
        // aspect-ratio: 1;
      }

      input[type="number"] {
        background-color: white;
        border: solid 1px #b6b6b6;
        border-radius: $border-radius;
        font-size: 3.2rem;
        font: Poppins;
        width: 6rem;
        height: clamp(7.5rem, 7.5rem, 6rem);
        background-color: #f8f8fb;
      }

      /* Firefox */
      input[type="text"] {
        -moz-appearance: textfield;
        // aspect-ratio: 1;
      }

      input[type="text"] {
        background-color: white;
        border: solid 1px #b6b6b6;
        border-radius: $border-radius;
        font-size: 3.2rem;
        font: Poppins;
        width: 6rem;
        height: clamp(7.5rem, 7.5rem, 6rem);
        background-color: #f8f8fb;
      }
    }
  }

  .payment-received-page-bottom {
    // *{
    //   border: solid 3px violet;

    // }

    left: 0;
    z-index: 3;
    position: fixed;
  }

  .bottom-nav-fixed {
    position: relative;
  }
}

// @media screen and (max-height: 790px) {
.payment-received-view {
  max-height: 100%;
  overflow-y: hidden;
}

.payment-received-page-bottom {
  h1 {
    transform: translateY(2rem);
  }

  .verify-phone-number-segment p {
    font-size: 1.3rem;
    padding: 0.1rem 2rem;
    font-family: var(--main-font-secondary) !important;
  }

  overflow-y: visible;
  bottom: -5rem;
  left: 0;
}
// }

@media screen and (max-width: 330px) {
  .verify-phone-number-segment {
    max-width: 100%;
    padding: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .verify-phone-number-segment {
    max-width: 65rem;
    margin-left: 25vw;
  }
}
@media screen and (max-height: 920px) {
  // shop more button needs new design/ size here
}
