.custom-input-red-border {
  input,
  select {
    border: solid 1px rgb(196, 71, 71) !important;
    /*  border-radius: 0.4rem !important; */
    /* border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; */
  }
  select {
    font-weight: 100 !important;
  }

  /* label {
    color: rgb(196, 71, 71) !important;
  } */
}

.custom-input-red-border-bottom {
  input,
  select {
    border-bottom: solid 1px rgb(196, 71, 71) !important;
    /*  border-radius: 0.4rem !important; */
    /* border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; */
  }
  select {
    font-weight: 100 !important;
  }

  /* label {
    color: rgb(196, 71, 71) !important;
  } */
}

.addressCountrySelector-red-text {
  /* select {
    color: #ce8585 !important;
  } */
  select {
    color: #abababa6 !important;
  }
}
