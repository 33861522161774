.old-user-summary {
  box-sizing: border-box;
  object-fit: contain;
  border: solid 1px #1890ff;
  border-radius: $border-radius;
  font-family: var(--main-font-primary);
  background-color: white;
  margin-bottom: 2rem;

  .flex-col {
  }

  &-address {
    display: flex;
    svg {
      margin-top: 0.5rem;
      fill: $main-black;
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
    }

    /* &-text{

    } */
  }

  .payment-method-summary {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    text-transform: capitalize;
  }

  .old-user-summary-payment-method-icon {
    max-height: 1.6rem;
  }

  p,
  h3 {
    margin: 0.4rem 0;
  }

  p {
    font-weight: 400 !important;
    font-family: var(--main-font-secondary) !important;
  }

  h3 {
    color: #9a9a9a;
    font-weight: 500 !important;
    font-size: 1.1rem;
    text-transform: uppercase;
  }

  .summary-user-phone {
    font-size: 1.2rem;
    font-weight: 200 !important;
    color: #2a2a2a;
    font-family: var(--main-font-secondary) !important;
  }

  .summarized-fast-checkout-segment {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.4rem 1.8rem;
    border-top: solid 1px #c9d8d9;
    max-height: 7rem;
    height: 7rem;
    position: relative;

    &:first-of-type {
      border-top: none;
    }

    /* .editButton-component {
      border: none;
      background-color: transparent;
      position: absolute;
      right: 1rem;

      // @media only screen and (max-width: 400px) {
      //   right: 3rem;
      // }
    } */

    /* .deleteButton-component {
      button {
        border: none;
        background-color: transparent;
        position: absolute;
        right: 3rem;

        @media only screen and (max-width: 400px) {
          right: 3rem;
        }
      }
    } */
  }
}

.heading-with-button-to-right {
  img {
    max-height: 4rem;
  }
}
