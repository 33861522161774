@import "../abstracts/variables";

.headerProfile-hidden {
  height: 6rem;
  width: 100%;
  color: transparent;
  display: none;

  &[data-is-header-profile-fixed="fixed"] {
    display: block;
  }
}

.headerProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $nav-back-color;
  height: 6rem;
  width: 100%;
  padding: 18px;
  color: $nav-font-color;
  position: relative;

  &[data-is-header-profile-fixed="fixed"] {
    position: fixed;
    z-index: 99;
  }

  &-max-width-container {
    position: relative;
    width: 100%;
    max-width: 120rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      max-width: 100%;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      height: 2rem;
      width: 2rem;
      margin-right: 1.5rem;

      fill: var(--font-color);
    }

    div {
      display: flex;
      align-items: center;

      h2 {
        font-size: 2rem !important;
        font-family: var(--main-font-primary) !important;
      }
    }
  }

  .img__container {
    height: 3rem;
    width: 3rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    cursor: pointer;
    @media only screen and (max-width: 1200px) {
      margin-right: 0rem;
      margin-left: 0rem;
    }

    img {
      height: 100%;
      width: 100%;
    }
    svg {
      fill: $nav-font-color;
    }
  }

  h1 {
    font-family: var(--main-font-primary);
    font-weight: 600 !important;
    text-transform: uppercase;
  }
}
