.layout2Fastcheckout {
  .fast-checkout-page {
    padding: 0;

    .shipping-details {
      margin-top: 1.6rem;
    }

    .billing-details {
      margin-top: 1.6em !important;
    }
  }

  .card-details-retracted {
    width: 100%;
  }

  .editButton-component button {
    box-shadow: none;
    background-color: #fff;
  }

  &__top {
    background-color: #fff;
    border-radius: 0.7rem;
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;
  }
  &__bottom {
    background-color: #fff;
    border-radius: 0.7rem;
    padding: 1rem 1rem;
  }
}

.fastCheckoutDesktopExtraLayout2-left {
  #paymentMethodsRef001 {
    background-color: #fff;
    padding: 1.4rem 1.82em;
    border-radius: 0.7rem;
  }

  .headingExpandable {
    border-top: none;
    padding: 0;
  }
}
/* .fastCheckoutDesktopExtraLayout2-right {
} */

.fastCheckoutDesktopExtraLayout2-userDetails {
  background-color: #fff;
  padding: 0rem 1.82em;
  border-radius: 0.7rem;
  margin-bottom: 2rem;

  /* .shipping-details {
    margin: 0;
    padding-top: ;
  }
 */
  .billing-details {
    margin: 0;
    padding: 1.75rem 0rem;
  }
}
