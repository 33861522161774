.country-input-field-custom {
  width: 90%;
  border: solid 0.1rem #c3d3d4 !important;
}

.modal-country-input {
  font-size: 1.8rem;
  padding: 1.6rem;
  border-radius: $border-radius;
  border: #c3d3d4 solid 1px;
}
