@import "../abstracts/variables";

.createAdUpAccountToggleTheme1 {
  margin-top: 1.6rem;
  font-family: var(--main-font-primary);

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    background-color: $main-white;
    border: solid #c3d3d4 1px;
    border-radius: var(--button-border-radius);
    padding: 1.2rem 1rem;
    box-shadow: $normal-box-shadow;
    transition: all 0.3s ease-in-out;

    &[data-is-create-account-true="true"] {
      background-color: #e2eef56c;
    }

    &-text {
      margin-bottom: 0.12rem;
      font-weight: 500 !important;
      color: $main-black;
    }
  }
}
