@import "../../abstracts/variables";

.productInfoSC {
  // padding: 0 1.7rem 0 0rem;
  padding: 0;
  width: 100%;
  // margin-top: $margin-top-double;
  margin-top: 1rem;

  @media only screen and (max-width: 750px) {
    padding: $title-area-padding;
  }

  h1,
  h2,
  p {
    color: $main-black !important;
    margin: 0rem;
    font-family: var(--main-font-secondary) !important;
  }

  &__top {
    &--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 1.9rem !important;
        font-weight: 500 !important;
        font-family: var(--main-font-primary) !important;
      }
      h1 {
        font-size: 2rem;
        font-weight: 700 !important;
      }
    }
    &--description {
      margin-top: $margin-top;
      p {
        font-size: 1.4rem;
        white-space: pre-wrap;
        font-family: var(--main-font-secondary) !important;
        a {
          font-size: 1.3rem;
          color: $main-blue;
          opacity: 65%;
          font-style: italic;
          cursor: pointer;
        }
      }
    }
  }
  &__bottom {
    margin-top: $margin-top;
    h2 {
      font-size: 1.6rem !important;
      font-weight: 500 !important;
    }
    p {
      margin-top: $margin-top;
      font-size: 1.3rem;
      font-family: var(--main-font-secondary) !important;
    }
  }

  &__price {
    margin-top: $margin-top;
    h1 {
      font-size: 3.6rem !important;
    }
  }
}
