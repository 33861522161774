.animated-input-regular {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.6rem;
  overflow: hidden;
  position: relative;
  font-family: var(--main-font-primary);

  input,
  input:focus {
    height: $input-height;
    /* padding: $input-padding;
    padding-top: 1.5rem; */
    transition: $normal-transition;
    outline: none;
    // color: $input-color;
    font-size: $input-font-size;
    width: 100%;
    // color: rgba(0, 0, 0, 0.542);

    &:disabled {
      background-color: var(--input-disabled-bg-color);
      cursor: not-allowed;
    }
  }
  input {
    border: solid 1px #c3d3d4;
    border-radius: $border-radius;
  }
  input:focus {
    border: solid 1px #1890ff !important;
  }

  /* label {
    position: absolute;
    font-weight: 200 !important;
    color: $placeholder-color;
    font-size: $input-font-size;
    padding: $input-padding;
    pointer-events: none;
    transition: $normal-transition;
  }

  input:focus + label,
  :not(input[value=""]) + label {
    font-size: 1.1rem;
    transform: translate(0, -140%);
    margin-top: 9px;
  } */

  label {
    position: absolute;
    font-weight: 200 !important;
    color: $placeholder-color;
    font-size: $input-font-size;
    padding: $input-padding;
    pointer-events: none;
    top: 2rem;
    transition: all 300ms ease-in-out;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  input:focus + label,
  :not(input[value=""]) + label {
    font-size: 1.1rem;
    top: 0.5rem;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }
}
