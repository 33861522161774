.payment-methods-searchbar {
  z-index: 30;
  position: fixed;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  bottom: 5rem;
  left: 0;
  background-color: rgba(0, 0, 0, 0.515);
}

.payment-methods-searchbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18rem;
  margin-left: 2rem;
  margin-right: 2rem;
  width: 50rem;
  font-family: var(--main-font-primary);
}
