@import "../../abstracts/variables";

.imageSliderSC {
  margin: $section-margin-double;
  padding: 0 0 0 1.7rem;
  width: 100%;
  @media only screen and (max-width: 750px) {
    padding: 0rem 1.7rem;
  }

  &__container {
    // background-color: $main-white;
    // height: 20rem;
    background-color: transparent;
    //max-height was removed due to aspect ratio settings
    /* max-height: 60rem; */
    width: 100%;
    border-radius: $border-radius;
    display: flex;
    overflow: hidden;
    position: relative;

    &[data-mobile-slider-ratio="1:1 (square)"] {
      img {
        width: 100%;
        object-fit: contain;
        aspect-ratio: 1/1;
        cursor: grab;
      }
    }
    &[data-mobile-slider-ratio="16:9 (wide)"] {
      img {
        width: 100%;
        object-fit: contain;
        aspect-ratio: 16/9;
        cursor: grab;
      }
    }
    &[data-mobile-slider-ratio="9:16 (tall)"] {
      img {
        width: 100%;
        object-fit: contain;
        aspect-ratio: 9/16;
        cursor: grab;
      }
    }

    // image styles for dynamic image
    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
      object-fit: contain;
      cursor: grab;
    }

    &--placeholder {
      position: relative;
      width: 100%;
      filter: blur(10px);
      background: #dddddd53;
      transform: scale(1);
      animation: pulsate 700ms alternate infinite ease;
    }

    &--buttonHolder {
      height: 3rem;
      width: 34rem;
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      span {
        height: 0.4rem;
        width: 3.5rem;
        background-color: rgba(143, 143, 143, 0.46);
        margin: 0rem 0.5rem;
        cursor: pointer;

        &[data-image-slider-button="true"] {
          background-color: rgba(225, 225, 225, 0.573);
        }
      }
    }
  }
}
