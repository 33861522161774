@import "../../abstracts/variables.scss";

.listNavigation {
  width: 50%;
  margin: 1.5rem 1.5rem;
  font-family: var(--main-font-primary);

  @media only screen and (max-width: 900px) {
    width: 92%;
  }

  &-main {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .title {
    color: gray !important;
    text-transform: uppercase;
    /* font-size: 1.3rem; */
    font-weight: 500 !important;
    font-size: 1.2rem !important;
    margin-bottom: 0 !important;
  }

  .listNavigation__saveUserData {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    h2 {
      font-weight: 500 !important;
      font-size: 1.6rem !important;
      margin-bottom: 0 !important;
      color: $main-blue;
      cursor: pointer;
      transition: $normal-transition;
      &:hover {
        color: $main-blue-hover;
      }
    }
  }

  .listNavigation__savedPaymentDetails {
    width: 100%;
  }
  .listNavigation__savedPaymentDetails > .new-payment-method-icon {
    width: 100%;
  }

  .listContainer {
    background-color: $main-white;
    margin-top: 1rem;
    -webkit-box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.33);
    border: 2px solid rgb(227, 227, 227);
    border-radius: $border-radius;

    .listItem {
      padding: 0.01rem 0rem 0rem 2.5rem;
      cursor: pointer;
      transition: $normal-transition;

      .listItem-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .listItem__title {
          color: $main-black;
          /* font-size: $input-font-size; */
          display: flex;

          h2 {
            font-weight: 500 !important;
            font-size: 1.6rem !important;
            margin-bottom: 0 !important;
          }

          h2[active="true"] {
            color: var(--theme-color);
          }

          span {
            margin-left: 0.5rem;
            font-weight: 400 !important;
          }
        }

        .listItem__button {
          img {
            height: 1.5rem;
            margin-right: 2rem;
          }

          &--toggle {
            height: 2rem;
            margin-right: 1rem;
          }
        }
      }

      .listItem-bottom {
        margin-top: 1.25rem;
        border-bottom: $input-border-normal;
      }
    }

    .listItem:hover {
      background-color: rgb(240, 240, 240);
    }

    .listItem:last-child .listItem-bottom {
      border-bottom: 1.7px solid transparent;
    }
  }
}
