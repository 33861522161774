@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  font-size: var(--main-font-size);
  font-size: 62.5%;
  color: var(--main-dark-font-color);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-color: $background-color;
  scroll-padding-block-end: 10%;
  scroll-padding-bottom: 10% !important;
  font-family: var(--main-font-primary);
}

#adUp-SSO-iFrame {
  position: fixed;
  z-index: 1000000000;
  width: 70%;
  height: 30%;
  left: 25%;
  border: none;
  background-color: transparent;
}

// the root div used in React
#root {
  width: 100%;
  font-size: 1rem;
  background-color: #343434;
}
.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-row-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

// Change the white to any color
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
} */
.max-width-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  &__content {
    width: 100%;
    max-width: 120rem;
  }
  &__back {
    width: 100%;
    max-width: 120rem;
    height: 4vh;
  }
}

a {
  text-decoration: none;
}

.app-container {
  margin-bottom: 5rem;
  // max-width: 120vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: $background-color;
  background-image: $background-image;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 10;

  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backface-visibility: hidden;
    z-index: -10;
  }
}

.app-container {
  /* max-width: var(--app-container-width-landscape);
    width: var(--app-container-width-landscape); */
  /* width: 40rem; */
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 5rem;
  position: relative;
  z-index: 10;
}

.pay-button-black {
  position: fixed;
  /* left: 28vw; */
  /* max-width: (40vw) !important;
    width: calc(60vw - 36px) !important; */
}

/* Change autocomplete styles in WebKit */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
} */

@media only screen and (max-width: 400px) {
  .app-container {
    width: 100%;
    position: relative;
    z-index: 10;
  }
}

@media only screen and (max-width: 375px) {
  html {
    font-size: 50%;
  }
}
@media only screen and (max-width: 300px) {
  html {
    font-size: 40%;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@media only screen and (max-width: 200px) {
  html {
    font-size: 27%;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media screen and (orientation: landscape) {
  .app-container {
    /* max-width: var(--app-container-width-landscape);
    width: var(--app-container-width-landscape); */
    /* width: 40rem; */
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
  }

  .pay-button-black {
    position: fixed;
    /* left: 20vw; */
    /* max-width: (60vw) !important;
    width: calc(60vw - 36px) !important; */
  }
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0rem;
}

/**
  Custom component styles
  */
.light-gray-border {
  border: solid 1px #c3d3d4;
  border-radius: $border-radius;

  width: 100%;
  outline: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 1px 0px 0px rgb(229, 229, 229);
  box-shadow: 0px 1px 0px 0px rgba(229, 229, 229, 0.93);
}

// stripe components styles

.stripe-container {
  // padding-top: 48px;
}

html:focus-within {
  min-height: calc(max-content * 2) !important;
  .app-container {
    margin-bottom: 0 !important;
    transform: translateY(-env(safe-area-inset-bottom)) forwards;
    bottom: 0;
  }
}

h2 {
  font-size: 1.75rem !important;
  /* color: var(--main-dark-font-color) !important; */
  font-weight: 700 !important;
}

button {
  cursor: pointer;
}

.main-content-holder {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    justify-content: center;
  }
}
.main-left-content-holder {
  width: 48%;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
}
.main-right-content-holder {
  width: 48%;
  @media only screen and (max-width: 750px) {
    margin-top: 2rem;
    width: 100%;
  }
}

.main-payment-content-holder {
  display: flex;
  width: 100%;
}

.main-payment-card-content-holder {
  width: 48%;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
}

.divider {
  height: 0.1rem;
  width: 100%;
  margin-top: 4rem;
  padding: 0rem 4rem;

  &-line {
    background-color: #c3d3d47c;
    width: 100%;
    height: 100%;
  }
}

.display-none {
  display: none !important;
}

.no-padding {
  padding: 0 !important;
}

/* ----------- pagination styles start ----------- */
.pagination {
  width: 100%;
  list-style: none;
  padding-left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  gap: 0.5rem;
  height: 7rem;
  flex-wrap: wrap;

  /*  @media only screen and (max-width: 750px) {
    width: 100%;
    font-size: 0.8rem;
    gap: 0.2rem;
  } */

  &__page-num {
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    border-radius: 0.3rem;
    font-weight: 500 !important;
    border: $input-border-small;
    background-color: $light-button-color;
    color: $main-black;
    /*  @media only screen and (max-width: 750px) {
      padding: 0.5rem 1rem;
    } */

    &:hover {
      background-color: var(--theme-color);
      color: var(--font-color);
    }
  }

  &__active {
    background-color: var(--theme-color);
    color: var(--font-color);
  }
}
/* ----------- pagination styles end ------------- */

.loading-status {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translate(-50, -50%);
  font-size: 1.5rem;
}

.deleteButton-component {
  position: absolute;
  right: 1rem;

  button {
    height: 2.6rem !important;
    width: 2.6rem !important;
    border: none;
    background-color: #e02020 !important;
    /* height: 2.3rem !important;
    width: 2.3rem !important; */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #464646; */
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    /* box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05); */
    transition: $normal-transition;
    color: #fff;
  }

  button:active {
    box-shadow: none;
  }

  &-iconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.6rem !important;
    width: 2.6rem !important;
    svg {
      transition: $normal-transition;
      height: 1.4rem !important;
      width: 1.4rem !important;
      fill: #fff !important;
    }
  }
}

.editButton-component[data-is-edit-btn-right-aligned="true"] {
  right: 1.8rem;
}
.editButton-component {
  position: absolute;
  right: 4.8rem;
  button {
    height: 2.6rem !important;
    width: 2.6rem !important;
    border: none;
    background-color: #f7f5f9;
    /* height: 2.3rem !important;
    width: 2.3rem !important; */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #464646; */
    cursor: pointer;
    /* -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78); */
    /* box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05); */
    transition: $normal-transition;
    color: #fff;
  }

  button:active {
    box-shadow: none;
  }

  &-iconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.6rem !important;
    width: 2.6rem !important;
    svg {
      transition: $normal-transition;
      height: 1.4rem !important;
      width: 1.4rem !important;
      fill: #8d8495;
    }
  }
}

////TEST WIDGET////
.testWidget {
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $main-white;
  padding: $title-area-height;
  border: 0.5px solid black;

  p {
    color: $main-black;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.4rem;
    font-weight: 500 !important;
    font-family: var(--main-font-secondary) !important;
  }
}

.rootPageInput {
  padding-left: 1.5rem;
}

.cookieConsent {
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &-text {
    font-size: 1.8rem;
    color: black;
    font-weight: 500;
    padding-bottom: 0.5rem;
    margin-right: 1rem;
  }

  svg {
    transition: all 0.3s ease;
    height: 1.8rem;
    width: 1.8rem;
    fill: gray;
  }

  &[data-cookie-options-open="true"] {
    svg {
      transform: rotate(180deg);
    }
  }
}
