.bottom-nav-fixed {
  background-color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0rem;
  height: 6.8rem;
  -webkit-box-shadow: 0rem 1rem 1.1rem-1.1rem#000000;
  box-shadow: 0rem 1rem 1.1rem-1.1rem#000000;
  display: none;
}

.nav-container {
  z-index: 10;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 6.8rem;
  padding: 0;
  margin: 0;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bottom-nav-fixed {
    background-color: rgba(0, 0, 0, 0.035) !important;
    -webkit-backdrop-filter: blur(1em) !important;
    backdrop-filter: blur(1.2em) !important;
  }
}
