@import "../../abstracts/variables";

.cartBannerSC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // padding: $title-area-padding;
  padding: 0;
  margin: $section-margin;
  height: 3rem;

  @media only screen and (max-width: 1200px) {
    padding-left: 4rem;
  }
  @media only screen and (max-width: 550px) {
    padding-left: 2rem;
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      transform: rotate(180deg);
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    h1 {
      font-size: 1.9rem;
      font-weight: 700 !important;
      color: $main-black;
      margin: 0rem;
      margin-bottom: 0.32rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    &--number {
      height: 2.7rem;
      width: 2.7rem;
      background-color: $main-black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 1.6rem;
        color: $main-white;
        margin-bottom: 0.1rem;

        &[data-cartBannerSC-number="true"] {
          font-size: 1.3rem;
        }
      }
    }
  }

  &__right {
    height: 2.7rem;
    width: 2.7rem;
    &--heart {
      width: 100%;
      height: 100%;
      background-color: #e60023;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
      box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
      transition: $normal-transition;
      img {
        margin-top: 0.1rem;
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }
}
