$new-payment-methods-border-radius: 0.4rem;
$new-payment-methods-border-color-selected: var(--theme-color);
$new-payment-methods-background-color-selected: rgb(220, 236, 255);

.payment-methods {
  display: flex;
  flex-direction: column;

  .new-payment-method-icon {
    background-color: white;
    border: solid #c3d3d4 1px;
    border-radius: var(--button-border-radius);
    overflow: hidden;
  }

  button.payment-method-add {
    background-color: white;
    border: solid 1px #818181;
    color: #818181;
    font-size: 1.4rem;
    padding: 0.4rem 0.6rem;
    border-radius: 0.3rem;
  }

  h1 {
    font-family: var(--main-font-primary);
  }

  b.view-all {
    font-family: var(--main-font-primary);
    font-size: 1.5rem;
    color: gray;
  }

  .payment-methods-heading {
    display: flex;
    align-items: center;
    svg {
      height: 2rem;
      width: 2rem;
      margin-right: 1rem;
    }
  }

  .payment-methods-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      margin-block-start: 0;
    }
  }

  .new-payment-method-heading {
    position: relative;
    font-family: var(--main-font-primary);
    margin-bottom: 5rem;
    hr {
      position: absolute;
      width: 100%;
      top: 0.3rem;
      border: solid 0.11px rgb(177, 175, 175);
      height: 1px;
    }

    span {
      position: absolute;
      background-color: #f6f6f6;
      z-index: 2;
      width: fit-content;
      left: calc(50% - 8.0015rem);
      font-family: var(--main-font-primary);
      font-style: normal;
      font-weight: 200 !important;
      font-size: 1.3rem;
      line-height: 1.7rem;
      /* identical to box height */

      letter-spacing: 0.05em;

      color: #393939;
    }
  }

  .payment-methods-new {
    // margin-top: 1rem;
  }
}

.card-details-retracted {
  border-radius: var(--button-border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.1rem;
  box-sizing: border-box;
  margin-bottom: 1.6rem;
  border: solid 1px #e6e3e3;
  background-color: white;
  height: 5.6rem;
  width: 48%; /* pay button fix */
  font-family: var(--main-font-primary);
  font-size: 1.8rem;

  &[data-simple-payment-layout="2"] {
    padding: 0;
    margin-bottom: 0rem;
  }
  &[data-option-payment-layout="2"] {
    padding: 0;
    margin-bottom: 0rem;
  }

  @media only screen and (max-width: 950px) {
    width: 100%;
  }

  h2 {
    margin: 0;
  }

  span {
    display: flex;
    color: #5a5a5a;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%; /* pay button fix */
    white-space: nowrap;
    gap: 1em;
  }

  button {
    background: none;
    width: 100%; /* pay button fix */
    border: none;
  }
}

.not-selected-payment-method {
  &[data-simple-payment-layout="2"] {
    border: none;
  }
  &[data-option-payment-layout="2"] {
    border: none;
  }
}

.selected-payment-method-retracted {
  background-color: rgb(220, 236, 255);
  border: solid 1px #1890ff;
  height: 5.6rem;
  width: 100%;
}
/* @media screen and (max-width: 390px) {
  .card-details-retracted span {
    width: 20rem;
  }
} */

.payment-methods-list {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 950px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

// on smaller screens, we split the payment methods list into two columns
/* @media screen and (min-width: 768px) {
  .payment-methods-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;

    .new-payment-method-icon {
    }
  }
} */
