.splash-screen {
  background-color: #f6f6f6 !important;
  background-image: #f6f6f6 !important;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  &-lottie {
    width: 40%;
    object-fit: contain;
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      animation: pulsate 600ms alternate infinite ease;
      width: 40%;
      object-fit: contain;
    }

    h1 {
      margin-top: 3.5rem;
      text-align: center;
      font-family: var(--main-font-primary);
      font-weight: 600 !important;
      font-size: 2.3rem;
      margin-bottom: 0rem;
    }
    p {
      text-align: center;
      font-family: var(--main-font-secondary) !important;
      font-weight: 400 !important;
      font-size: 2.2rem;
      margin-top: 0.5rem;
    }
  }
}
