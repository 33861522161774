@import "../../abstracts/variables.scss";

.footer {
  /* width: 92%; */
  margin: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: var(--main-font-primary);

  h2 {
    font-weight: 600 !important;
    font-size: 1.6rem !important;
    margin-bottom: 0 !important;
    color: var(--theme-color);
    cursor: pointer;
  }

  span {
    margin-top: $margin-top;
    font-weight: 400 !important;
    font-size: 1.6rem !important;
    color: $placeholder-color;
  }
}
