.userDetails {
  overflow: hidden;
  /* margin-top: 2rem; */
  height: auto;
  padding-top: 0.1rem;
  border: solid 1px #c3d3d4 !important;
  border-radius: $border-radius;
  box-shadow: $normal-box-shadow;

  &-wraper {
    // margin-bottom: 1.8rem;
  }

  &:focus-within {
    border: solid 1px #1890ff !important;
  }

  .animated-input-regular {
    margin-top: 0;

    .animated__input {
      border: solid 1px #fff !important;
      border-bottom: solid 1px #c3d3d4 !important;
    }
    .animated__input:focus {
      transition: $normal-transition;
      border-bottom: solid 1px #1890ff !important;
    }
  }

  .animated-input-regular > input {
    border-radius: 0px !important;
  }
  .animated-input-regular #border-bottom-none {
    border-bottom: none !important;
  }

  .phone-number-with-country-modal {
    border: solid 1px #fff !important;
    border-bottom: solid 1px #c3d3d4 !important;
    border-radius: 0px !important;
  }
}
