@import "../../sass/abstracts/variables";

.roundButton-component {
  &-container {
    height: 2.6rem !important;
    width: 2.6rem !important;
    border: none;
    background-color: transparent;
    /* height: 2.3rem !important;
    width: 2.3rem !important; */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #464646; */
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    /* box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05); */
    transition: $normal-transition;
    color: #fff;

    &:active {
      box-shadow: none;
    }
  }

  &-iconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    svg {
      transition: $normal-transition;
      height: 1.4rem !important;
      width: 1.4rem !important;
      fill: $dark-icon-color;
    }
  }
}

.roundButton-icon-dark {
  svg {
    fill: $light-icon-color;
  }
}

.icon-rotate {
  transition: $normal-transition;
  transform: rotate(180deg);
}
.icon-rotate-half-left {
  transition: $normal-transition;
  // transform: rotate(135deg) translateY(0.3rem) translateX(-0.2rem);
  transform: rotate(135deg);
  /*  transform: translateY(1rem); */
}
.icon-rotate-half-right {
  transition: $normal-transition;
  transform: rotate(0deg);
}
