:root {
  // fonts
  --theme-color: #000000;
  --font-color: #f6f6f6;
  --back-color: #f6f6f6;
  --back-image: "";
  --backup-font: Helvetica, Arial, sans-serif;
  --main-font-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --main-font-secondary: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  // --main-font-primary-semi-bold: -apple-system, BlinkMacSystemFont, "Segoe UI",
  //   Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  // --main-font-primary-bold: -apple-system, BlinkMacSystemFont, "Segoe UI",
  //   Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  // colors
  --payment-methods-new-bg-color: #d8e3f382;
  --theme-teal: var(--theme-color);
  --theme-teal-bright: var(--theme-color);
  --pay-button-color: var(--theme-color);
  --main-font-size: 1rem;
  --button-border-radius: 0.7rem;
  --verify-alert-red: #ca1a17;
  --main-dark-font-color: #2e333d;
  --main-teal-color: #1c6168;

  // box shadows
  --box-shadow-gray-light: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);
  --box-shadow-teal-light: 0rem 1rem 1.1rem -1.1rem var(--theme-teal);

  // widths and heights
  --app-container-width-landscape: clamp(20vw, 64rem, 64rem);

  --input-disabled-bg-color: #efefef;
}

/* colors */
$nav-back-color: var(--theme-color);
$nav-font-color: var(--font-color);
$background-color: var(--back-color);
$background-image: var(--back-image);
$pay-button-color: var(--pay-button-color);
$background-grey: #f6f6f6;
$main-white: #fff;
// $main-black: #000;
$main-black: #2e333d;
$main-gray: #464646;
$main-light-gray: #f6f6f6;
$main-blue: #1890ff;
$main-blue-hover: #0452ac;
$main-red: #e02020;
$error-text: #aa0000;
$error-back: #fcdcd3;
$success-text: #00b317;
$success-back: #e8fce1;
$info-text: #ac790c;
$info-back: #f6f096;

$placeholder-color: rgb(171, 171, 171);
$input-color: rgb(99, 99, 99);
$dark-icon-color: #9a9a9a;
$light-icon-color: #f7f5f9;

$input-border-normal: 1.7px solid rgb(227, 227, 227);
$input-border-dark: 1.7px solid rgb(198, 198, 198);
$input-border-large: 2px solid rgb(227, 227, 227);
$input-border-small: 0.5px solid var(--containers-border-color);
$input-border-small-hover: 0.5px solid #a2a5b9;
// $input-border-focus: 1.7px solid #1890FF;
$input-border-focus: 1.7px solid var(--theme-color);
$input-border-focus-small: 1px solid var(--theme-color);
$input-border-light-blue: 1px solid #c3d3d4;
$input-border-1: 1px solid var(--input-border-color);
$input-border-1-focus: 1px solid #000000;
$input-border-2: 1px solid var(--input-border-hover-color);
$normal-border-radius: 0.7rem;

$input-background-focus: #dae7f6;
$dark-button-color: #464646;
$light-button-color: #f7f5f9;
// $item-hover-color: rgb(227, 227, 227);
$item-hover-color: rgb(240, 240, 240);
$item-border: 1px solid rgb(227, 227, 227);
$item-background-color: #f7f5f9;
$popup-overlay-color: rgba(0, 0, 0, 0.7);

/*common fonts sizes*/
$input-font-size: 1.7rem;

/*=================common measurements==================*/
//padding
$title-area-padding: 0rem 1.7rem;
$input-padding: 0rem 1.5rem;

//margin
$section-margin: 1rem 0rem 1rem 0rem;
$section-margin-double: 2rem 0rem 1rem 0rem;
$margin-top: 1rem;
$margin-top-small: 0.5rem;
$margin-top-large: 2rem;
$margin-top-double: 1.5rem;

//height
$title-area-height: 2rem;
$input-height: 6rem;

//width
$input-width: 95%;

//border
$border-radius: 0.7rem;

/* transitions */
$normal-transition: all 0.3s ease;
$slide-transition: all 0.7s ease-in-out;

//shadows
$normal-box-shadow: 0px 0px 15px -11px rgba(0, 0, 0, 0.59);
$normal-box-shadow-plus: 0px 3px 10px -6px rgba(0, 0, 0, 0.84);
