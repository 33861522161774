.new-shipping-address-form {
  border: solid 1px #1890ff;
  border-radius: $border-radius;
  background-color: white;
  margin-bottom: 1.6rem;
  overflow: hidden;

  /*  div {
   
  } */

  .new-shipping-address-form-top {
    border-bottom: solid 1px #c3d3d4;
    position: relative;
    padding: 0.4rem 1.1rem;

    .buttons-holder-flex-row {
      position: absolute;
      right: 0;
      top: 0rem;
      display: flex;
      padding: 0.4rem 1.1rem;

      .roundButton-component-container {
        min-height: max-content;
        position: relative;
        z-index: 5;
        top: 1.5rem;
        margin-right: 1rem;
        right: -0.5rem;
      }
    }
  }

  input {
    border: none;
    box-shadow: none;
  }

  label {
  }

  .new-address-autocomplete-container {
    height: 5.6rem;
    position: relative;
    border-bottom: solid 1px #c3d3d4;
    transition: all 0.3s ease-in-out;

    &:focus-within {
      border-bottom: solid 1px #1890ff !important;
    }

    input {
      margin-top: 0.7rem;
      width: 90%;
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: $main-white !important;
    }

    .new-address-arrow-svg {
      position: absolute;
      right: 1.6rem;
      top: 1.5rem;
      background-color: #f7f5f9;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
      box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
      height: 2.6rem !important;
      width: 2.6rem !important;
      border: none;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1.4rem !important;
        width: 1.4rem !important;
        fill: $dark-icon-color;
        transition: all 0.3s ease-in-out;
      }

      &[data-new-address-arrow-svg="true"] {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .edit-button {
    background-color: #00000000;
    padding: 0;
    max-width: min-content;
    height: min-content;
    position: absolute;
    border: none;

    top: 1.1rem;
    right: 1.1rem;
    img {
      margin: 0;
      max-width: 3.6rem;
      max-height: 3.6rem;
      object-fit: fill;
    }
  }

  .new-address-form-manual-fields {
    padding: 0;

    .manual-address-field {
      font-weight: 200 !important;
      position: relative;
      // border-top: solid 1px #c9d8d9;
      width: 100%;
      input,
      select,
      input:focus,
      select:focus {
        font-size: 1.7rem;
        border: none;
        box-shadow: none;
        width: 100%;
        height: 5rem;
        outline: none;
        color: var(--main-dark-font-color);
      }

      input,
      input:focus {
        padding: 2rem 1.1rem 1.1rem 1.1rem;
      }
      select,
      select:focus {
        // padding: 2rem 0rem 1.1rem 1.1rem;
      }

      label {
        left: 1.1rem;
        position: absolute;
        font-size: 1.7rem;
        font-family: var(--main-font-primary);
        top: 2rem;
        transition: all 300ms ease-in-out;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        transform-style: preserve-3d;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
      }

      input:focus + label,
      :not(input[value=""]) + label {
        font-size: 1.1rem;
        top: 0.5rem;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        transform-style: preserve-3d;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
      }
    }
  }
}

// manual fields' styles can be reused
.manual-address-field {
  font-weight: 200 !important;
  position: relative;
  // border-top: solid 1px #c9d8d9;
  width: 100%;
  input,
  select,
  input:focus,
  select:focus {
    // font-size: 1.7rem;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 5rem;
    outline: none;
  }

  label {
    left: 1.1rem;
    position: absolute;
    font-size: 1.7rem;
    font-family: var(--main-font-primary);
    top: 2rem;
    transition: all 300ms ease-in-out;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  input:focus + label,
  :not(input[value=""]) + label {
    font-size: 1.1rem;
    top: -0.8rem !important;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  #new-address-number {
    border-bottom: solid 1px #fff !important;
  }

  #new-address-country,
  #new-address-city,
  #new-address-province,
  #new-address-postcode,
  #new-address-street1,
  #new-address-street2 {
    border-bottom: solid 1px #c9d8d9;
  }
}
