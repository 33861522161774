.shipping-address-form {
  h1 {
    font-family: var(--main-font-primary);
  }

  b.view-all {
    font-family: var(--main-font-primary);
    font-size: 1.5rem;
    color: var(--theme-teal);
  }

  .shipping-address-form-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: white;
      border: solid 1px #818181;
      color: #818181;
      font-size: 1.4rem;
      padding: 0.4rem 0.6rem;
      border-radius: 0.3rem;
    }
  }
}
