.overviewPageMobileLayout2-fastcheckout {
  .fast-checkout-page {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .max-width-container__content {
    border-top: 1px solid #b6b6b6;
    padding-top: 2rem;
  }
}
