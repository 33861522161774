/* Chrome, Safari, Edge, Opera */
.otp-inputs-holder input::-webkit-outer-spin-button,
.otp-inputs-holder input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otp-inputs-holder input[type="number"] {
  -moz-appearance: textfield;
  /* aspect-ratio: 1/ 1; */
  text-align: center;
  font-size: 32px;
}

/* Firefox */
.otp-inputs-holder input[type="text"] {
  -moz-appearance: textfield;
  /* aspect-ratio: 1/ 1; */
  text-align: center;
  font-size: 32px;
}
