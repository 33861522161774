@import "../abstracts/variables";

.imageInput {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15rem;
  background-color: white;

  &-outer {
    position: relative;
    overflow: hidden;
    border: solid 1px #c3d3d4;
    border-radius: $border-radius;
    height: 20rem;
    width: 100%;
    transition: $normal-transition;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    background-color: white;

    &:hover {
      border: solid 1px #c3d3d4;
    }

    &--label {
      position: absolute;
      font-size: 1.1rem;
      font-weight: 80 !important;
      color: $placeholder-color;
      left: 1.5rem;
      top: 0.5rem;
      transition: all 300ms ease-in-out;
      pointer-events: none;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      transform-style: preserve-3d;
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    }
  }

  &__top {
    height: 7.5rem;
    width: 7.5rem;
    background-color: var(--input-background-color);
    border: 1px solid rgb(197, 197, 197);
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 0.5rem;
    label {
      img {
        height: 100%;
        width: 100%;
        cursor: pointer;
        object-fit: contain;
      }

      div {
        height: 4rem;
        width: 4rem;
        transition: all 0.3s ease;
        &:hover {
          cursor: pointer;
        }

        svg {
          transition: all 0.3s ease;
          fill: rgb(197, 197, 197);
          height: 100%;
          width: 100%;

          &:hover {
            fill: $nav-back-color;
          }
        }
      }
    }

    input {
      display: none;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      color: var(--input-text-color);
      font-size: 1.4rem;
      margin-bottom: 0rem;
      font-family: var(--main-font-secondary) !important;

      &[error="false"] {
        color: #a2a5b9;
      }
      &[error="true"] {
        color: crimson;
      }
    }

    span {
      font-family: var(--main-font-primary);
      font-size: 1.1rem;
      font-weight: 300 !important;
      color: #a2a5b9;
      max-width: 100%;
      text-align: center;
    }

    a {
      font-family: var(--main-font-primary);
      font-size: 1.1rem;
      font-weight: 300 !important;
      color: $error-text;
      max-width: 100%;
      text-align: center;
      cursor: pointer;
    }
  }
}
