.payButtonTheme2 {
  margin-top: 1rem;
  border-radius: 0.8rem;
  background-color: var(--theme-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2rem;
  cursor: pointer;
  -webkit-box-shadow: 0px 7px 29px -13px rgba(0, 0, 0, 0.66);
  box-shadow: 0px 7px 29px -13px rgba(0, 0, 0, 0.66);
  margin-bottom: 2rem;

  h2 {
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  svg {
    fill: #fff;
    height: 2rem;
    width: 2rem;
    margin-left: 1rem;
    transform: rotate(180deg);
  }
}
