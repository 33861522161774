.address-form-with-search {
  position: relative;
  border: solid 1px #c3d3d4 !important;
  margin-top: 1.6rem;
  border-radius: $border-radius;
  overflow: hidden;
  background-color: #fff;
  transition: all 0.9s ease;

  &:focus-within {
    border: solid 1px #1890ff !important;
  }

  &[data-is-address-expanded="true"] {
    transition: all 0.9s ease;
    border: solid 1px #1890ff !important;

    #address-form-number-input {
      border-bottom: solid 1px #fff !important;
    }

    .plain-address-field {
      input:focus {
        border-bottom: solid 1px #1890ff !important;
      }
    }
  }
  &[data-is-address-expanded="false"] {
    transition: all 0.9s ease;
    input,
    select {
      border: 1px solid #fff !important;
    }
  }

  &[data-is-address-valid="false"] {
    transition: all 0.9s ease;
    border: solid 1px rgb(196, 71, 71) !important;
    /* label {
      color: rgb(196, 71, 71) !important;
    } */
  }

  .address-search-arrow-svg {
    position: absolute;
    right: 1.5rem;
    top: 2rem;
    background-color: #f7f5f9;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    height: 2.6rem !important;
    width: 2.6rem !important;
    border: none;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 1.4rem !important;
      width: 1.4rem !important;
      fill: $dark-icon-color;
      transition: all 0.3s ease-in-out;
    }

    &[data-address-search-arrow-svg="true"] {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .cross-button {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
  }

  .edit-button {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0.8rem;
    top: 0.9rem;
  }

  label {
    font-weight: 200 !important;
    top: 2rem;
    transition: all 300ms ease-in-out;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  .plain-address-field {
    width: 100%;
    display: flex;
    align-items: center;
    /* margin-top: $margin-top; */
    overflow: hidden;
    position: relative;
    font-family: var(--main-font-primary);

    input,
    input:focus {
      height: $input-height;
      padding: $input-padding;
      padding-left: 1.1rem;
      padding-top: 1.5rem;
      transition: $normal-transition;
      outline: none;
      /* color: $input-color;
      font-size: $input-font-size; */
      width: 100%;
      /* color: rgba(0, 0, 0, 0.542); */
    }
    input {
      letter-spacing: 0.05em;
      font-size: 1.6rem;
      color: var(--main-dark-font-color);
      border: none;
      transition: all 0.9s ease;
      border-bottom: solid 1px #c3d3d4;
      border-top-left-radius: $normal-border-radius;
      border-top-right-radius: $normal-border-radius;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    input:focus {
      // border-bottom: solid 1px #1890FF !important;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: 0.07em;
    }

    /* label {
      position: absolute;
      font-weight: 200 !important;
      color: $placeholder-color;
      font-size: $input-font-size;
      padding: $input-padding;
      pointer-events: none;
      transition: $normal-transition;
    }
  
    input:focus + label,
    :not(input[value=""]) + label {
      font-size: 1.1rem;
      transform: translate(0, -140%);
      margin-top: 9px;
    } */

    label {
      position: absolute;
      font-weight: 200 !important;
      color: $placeholder-color;
      font-size: $input-font-size;
      pointer-events: none;
      top: 2rem;
      transition: all 300ms ease-in-out;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      transform-style: preserve-3d;
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    }

    input:focus + label,
    :not(input[value=""]) + label {
      font-size: 1.1rem;
      top: 0.5rem;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      transform-style: preserve-3d;
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    }
  }
}
