.order-page {
  padding: 2rem;
  box-sizing: border-box;
  min-height: 100vh;

  .order-pay-button {
    width: clamp(40%, 33.5rem, 80%);
    background-color: var(--theme-teal-bright);
    min-height: 6.6rem;
    font-family: var(--main-font-primary);
    color: var(--main-dark-font-color);
    -webkit-box-shadow: 0rem 0rem 0.8rem -0.2rem var(--theme-teal);
    box-shadow: 0rem 0rem 0.8rem -0.2rem var(--theme-teal);
    height: 6rem;
    border-radius: $border-radius;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }

  .order-pay-segment {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-2rem);
    bottom: 10rem;

    //   text-align: center;
  }

  .field-with-input-below {
    display: flex;
    flex-direction: column;
    width: 100%;

    b {
      font-size: 1.6rem !important;
    }

    input {
      max-height: 5.6rem;
      min-height: 1.6rem;
      text-align: left;
    }

    .input-with-card-icon {
      min-width: 100%;

      input,
      div {
        max-height: 5px;
        width: 100%;
      }

      img {
        display: none;
      }
    }
  }

  input {
    border-radius: $border-radius;
    border: 1px solid #c3d3d4;
    padding: 1.6rem;
    display: flex;
  }

  .heading-with-back {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
      font-family: var(--main-font-primary);
      font-size: 2.4rem;
    }

    a {
      position: absolute;
      top: 2.4rem;
      left: 0;
    }
  }
  b {
    font-family: var(--main-font-primary);
  }

  .card-data-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .credit-card-input {
      grid-column: 1 / 2;
    }

    .card-date-cvc {
      display: flex;
      gap: 1.6rem;

      .card-expiry-date,
      .input-with-card-icon {
        max-width: 100% !important;
      }

      .input-with-card-icon {
        display: flex;
      }
    }
  }

  .order-input-fields {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  .billing-address-holder {
    span {
      display: flex;
      flex-direction: row;
      gap: 1.3rem;

      font-size: 1.3rem;

      button {
        padding: 0;
        border: none;
        max-height: min-content;
        background-color: rgba($color: #000000, $alpha: 0);
      }
    }
  }
}

.order-page > * {
  box-sizing: border-box;
}
