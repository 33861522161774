.theme1-productTitle {
  width: 100%;
  h2 {
    letter-spacing: -0.2px;
    line-height: 140%;
    font-size: 3.4rem !important;
    color: #0b0f0e;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    margin-top: 1rem;
  }
  &-other {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    &-rating {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      svg {
        height: 2.4rem;
        width: 2.4rem;
        fill: rgb(255, 168, 7);
        margin-right: 0.5rem;
      }
      span {
        font-size: 1.3rem;
        color: #0b0f0e;
        font-weight: 500 !important;
      }
    }
    &-sales {
      font-size: 1.3rem;
      color: #0b0f0e;
      font-weight: 500 !important;
    }
  }
}
