@import "../../sass/abstracts/variables";

.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin: 2rem; */
  /* height: 100vh; */
  height: 100%;
  width: 100%;

  &__lottie {
    max-height: 40rem;
    max-width: 40rem;
  }

  &__text {
    font-size: 1.4rem;
    font-weight: 500 !important;
    font-family: var(--main-font-primary);
    color: var(--main-dark-font-color);
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
