@import "../../abstracts/variables";

.cartOperatorSC {
  width: 100%;
  margin-top: 1rem;
  @media only screen and (max-width: 750px) {
    padding: $title-area-padding;
  }

  h2,
  p {
    margin: 0rem;
    font-family: var(--main-font-secondary) !important;
  }

  &--container {
    font-family: var(--main-font-primary);
    // margin: 0 1.7rem 0 0;
    margin: 0;

    @media only screen and (max-width: 750px) {
      // padding: $title-area-padding;
      margin: 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;

    &--left {
      font-weight: 500 !important;
      font-size: 1.6rem !important;
      margin-right: 1rem !important;
    }
    &--right {
      display: flex;
      align-items: center;

      &-minusBtn,
      &-plusBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 0.5px #c3d3d4;
        border-radius: $border-radius;
        background-color: $main-white;
        height: 2.5rem;
        width: 2.5rem;
        svg {
          width: 1.3rem;
          height: 1.3rem;
          fill: $main-black;
        }
      }
      &-minusBtn {
        margin-right: 1rem;
      }
      &-plusBtn {
        margin-left: 1rem;
      }
      p {
        font-size: 1.6rem;
        font-weight: 500 !important;
        color: $main-black;
        font-family: var(--main-font-secondary) !important;
      }
    }
  }

  &__bottom {
    margin-top: 2.6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      font-size: 1.6rem !important;
      font-weight: 500 !important;
      border-radius: 0.5rem;
      background-color: var(--pay-button-color);
      font-size: 1.5rem !important;
      font-weight: 600 !important;
      color: var(--font-color);
      width: 100%;
      padding: 1.3rem 4rem;
      border: none;
    }
  }
}
