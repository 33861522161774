/* simple animations */

.animated-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes appearWithFadeFromAbove {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translate3d(0, -150%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hiddenToShown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes displayTextSlow {
  from {
    color: #00000000;
  }

  to {
    color: black;
  }
}

@keyframes ballToHorizontalPill {
  0% {
    color: #00000000;
    max-width: 1.2em;
  }

  25% {
    max-width: 1.6em;
  }

  50% {
    max-width: 2.4em;
  }

  75% {
    max-width: 4em;
  }

  85% {
    max-width: 6em;
  }

  95% {
    max-width: 7em;
    color: #00000000;
  }

  100% {
    max-width: 8em;
    width: auto;
    color: white;
  }
}

@keyframes pulsate {
  from {
    opacity: 0.3;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1.1);
  }
}

// @keyframes toast-appear {
//     from {
//       opacity: 0;
//       widows: 1px;
//     }

//     to {
//       opacity: 1;
//     }
// }
