@import "../../../abstracts/variables";

.layout2Mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  &__container1 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    &--product {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      &-image {
        width: 100%;
      }

      &-detail {
        width: 100%;
        margin-top: 1.75rem;

        .productInfoSC {
          margin-top: 0;
          padding: 0;
        }
      }
    }
  }

  &__container2 {
    width: 100%;

    &--review {
      width: 100%;
      height: 60vh;
      background-color: rgb(231, 231, 231);
      border-radius: 0.7rem;
      margin-top: 1.75rem;
      padding: 1.5rem;

      font-family: var(--main-font-primary);
      color: var(--main-dark-font-color);
      font-size: 1.75rem;
      font-weight: 500;
      margin-bottom: 1.75rem;
    }

    &--top {
    }
    &--bottom {
    }
  }
}
