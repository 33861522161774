.new-shipping-address-page {
  --padding-around-view: 1.8rem;

  .form-group {
    input {
      width: 100% !important;
    }
  }

  padding: var(--padding-around-view);
  font-family: var(--main-font-primary);

  h1 {
    font-family: var(--main-font-primary);
    font-weight: bold !important;
  }

  h3 {
    font-family: var(--main-font-primary);
    font-weight: bold !important;
    margin-bottom: 0.6rem;
    font-size: 1.4rem;
  }

  input[type="text"] {
    padding: 1.2rem;
    border-radius: $border-radius;
    border: solid 1px #c3d3d4;
    letter-spacing: 5%;
    font-size: 1.6rem;
    width: calc(100% - (var(--padding-around-view)));
  }

  p.phone-info {
    display: flex;
    align-items: center;
    color: #424d59;
  }

  .address-form {
    position: relative;

    h3 {
      margin-bottom: 2rem;
    }

    position: relative;
    width: 100%;
  }
  .save-address-button-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    left: 0;

    button {
      width: 100%;
      max-width: 33.5rem;
      color: var(--main-dark-font-color);
      background-color: white;
      border: solid 0.12rem var(--main-dark-font-color);
      border-radius: $border-radius;

      height: 6rem;
      font-family: var(--main-font-primary);
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: left;
      width: 100%;
      border-radius: $border-radius;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      max-width: 33.5rem;
      color: var(--main-dark-font-color);

      margin-top: 2.5rem;
    }
  }

  .detect-location {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    color: #0a90db;
  }

  .google-map-holder {
    // add an appear animation here
    border-radius: $border-radius;
    border: solid 1px #424d59 !important;
    overflow: hidden;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.heading-with-back-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  div {
    padding: 0px 1rem 1rem 0;
    position: absolute;
    left: 0;
    top: 1.2rem;
  }
}
