.socialCommerce {
  &__goto-links--container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: $margin-top-large;
    margin-top: $margin-top-large;
  }

  &__browseMoreLink {
    color: $main-blue;
    font-size: 1.5rem;
    font-weight: 700 !important;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0.5rem;
  }
  &__goToLink {
    text-align: center;
    color: gray;
    font-size: 1.5rem;
    font-weight: 700 !important;
    margin-bottom: 0;
    margin-top: 0;

    a {
      color: $main-blue;
      font-size: 1.5rem;
      font-weight: 700 !important;
      cursor: pointer;
      margin-left: 0.5rem;
    }
  }
  &__productsLoading {
    text-align: center;
    margin-top: $margin-top-large;
    margin-bottom: $margin-top-large;
    font-size: 1rem;
    font-weight: 400 !important;
    cursor: pointer;
  }

  &__variantNotAvailable {
    text-align: center;
    margin-top: $margin-top-large;
    color: red;
    font-size: 1.2rem;
    font-weight: 400 !important;
  }

  &__productsHolder {
    margin-top: $margin-top-double;
    padding: $title-area-padding;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media only screen and (max-width: 1200px) {
      padding: $title-area-padding;
    }
    @media only screen and (max-width: 600px) {
      justify-content: space-evenly;
    }
    @media only screen and (max-width: 350px) {
      justify-content: center;
    }
  }
}

.socialCommerce__desktop--container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  // width: 100%;

  @media only screen and (max-width: 1200px) {
    // padding: 0 2rem;
    padding: 0 4rem;
  }
  @media only screen and (max-width: 750px) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
  }

  &-left {
    width: 48%;
    /* padding-left: 2rem; */
    padding-right: 0rem;
    @media only screen and (max-width: 750px) {
      padding-left: 2rem;
      padding-right: 2rem;
      width: 100%;
    }
    @media only screen and (max-width: 550px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    &-desktopSlider {
      display: block;
      margin-top: 1rem;
      @media only screen and (max-width: 750px) {
        // display: none;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 3.2rem; // this means normal margin which is 1.6rem is doubled for main sections.
      }
    }
    &-mobileSlider {
      display: none;
      /* @media only screen and (max-width: 750px) {
        display: block;
      } */
    }
  }
  &-right {
    // padding-top: 1rem;
    padding-top: 0rem;
    width: 48%;
    // padding-left: 2rem;
    padding-left: 0rem;
    padding-right: 0rem;
    @media only screen and (max-width: 750px) {
      padding-left: 2rem;
      padding-right: 2rem;
      width: 100%;
    }

    @media only screen and (max-width: 550px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
}
