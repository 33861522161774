@import "../../abstracts/variables.scss";

.orderDetails__overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: $popup-overlay-color;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .deleteAddressMsg__wrapper {
    width: 35rem;
    background-color: transparent;
  
    @media only screen and (max-width: 400px) {
      width: 90%;
    } */

.orderDetails__wrapper {
  width: 60%;
  background-color: transparent;
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 1000px) {
    width: 80%;
  }
  @media only screen and (max-width: 400px) {
    width: 90%;
  }
  /*   @media only screen and (max-width: 375px) {
    width: 200%;
  }
  @media only screen and (max-width: 318px) {
    width: 80%;
  }
  @media only screen and (max-width: 260px) {
    width: 70%;
  } */

  .orderDetails-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.2rem;
    h1 {
      color: $main-white;
      font-weight: 400 !important;
    }
  }

  .orderDetails__content {
    width: 100%;
    min-height: 35rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    margin: 1rem 0rem 2rem 0rem;
    border: $input-border-normal;
    border-radius: $border-radius;
    background-color: $main-light-gray;

    h2 {
      font-size: 1.5rem !important;
      font-weight: 500 !important;
    }
    h2,
    p {
      margin-top: 0 !important;
      margin-bottom: 0.5rem !important;
      font-family: var(--main-font-secondary) !important;
    }

    .orderDetails__section {
      width: 100%;
      min-height: 7rem;
      border-top: $input-border-normal;
      padding: 1rem;
      //   color:#464646;
      color: #000;

      .orderDetails__content--title {
        color: $placeholder-color;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 600 !important;
      }
    }

    .orderDetails__content--webshop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: none;

      p {
        font-size: 1.8rem;
        font-weight: 400 !important;
        font-family: var(--main-font-secondary) !important;
      }
      span {
        margin-top: 0.5rem;
        font-size: 1.2rem;
        font-weight: 400 !important;
      }
    }

    .orderDetails__content--paymethod {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      p {
        margin-top: 0.1rem;
        font-size: 1.3rem;
        font-weight: 400 !important;
        font-family: var(--main-font-secondary) !important;
      }
    }

    .orderDetails__content--paymethod > .bottom {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;

      .right {
        margin-left: 2rem;
      }

      .left > img {
        height: 4rem;
        width: 4rem;
      }
    }

    .orderDetails__content--normal {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      p {
        margin-top: 0.2rem;
        font-size: 1.3rem;
        font-weight: 400 !important;
        font-family: var(--main-font-secondary) !important;
      }

      span {
        margin-top: 0.2rem;
        font-size: 1.3rem;
        font-weight: 400 !important;
      }
    }
  }
}

.orderDetails-title-svg,
.orderDetails-right-svg {
  height: 3rem;
  width: 3rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main-white;
  border-radius: 50%;
  box-shadow: $normal-box-shadow-plus;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: $input-color;
  }
}
