/**
There are 3 kinds of cart views: expanded, retracted and loading.
**/

/**
Retracted cart view
**/
.cart-view {
  display: block;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 100%;
  min-height: 12rem;
  margin: 0;
  padding: 0;
  z-index: -1;

  .cart-item-inner {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    &.error {
      background-color: hsl(0, 0%, 85%) !important;
      border: solid 2px red !important;
    }
  }

  .cart-item {
    position: absolute;
    border-radius: 1rem;
    background-color: white !important;
    // border: solid 0.5px #e2e0e1;

    &.error {
      background-color: hsl(0, 0%, 85%) !important;
      border: solid 2px red !important;
    }
  }

  // .cart-item:first-of-type {
  //   z-index: 12 !important;
  // }

  @for $i from 4 to 99 {
    .cart-item:nth-of-type(#{$i}) {
      display: none;
    }
  }
}

@for $i from 1 to 10 {
  .cart-item-container:nth-of-type(#{$i}) {
    // background-color: lighten(#2af3cc, $i * 9.9%);
    z-index: calc(#{$i * -1});
    // width: calc(100% - (#{($i - 1) * 20}px));
  }
}

/**
Expanded cart view as a list
**/
.cart-view-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* margin-bottom: 8rem; */
  min-height: max-content;
  overflow-x: visible;
  position: relative;
  width: 100%;
  z-index: 0;
  background-color: rgba(246, 246, 246, 0.734);
  padding: 1rem;

  .collapse-container {
    position: fixed;
    background-color: white;
    width: 8rem;
    height: 8rem;
    border-radius: 9999rem;
    box-shadow: 0rem -0.2rem 0.5rem 0rem rgba(0, 0, 0, 0.23);
    bottom: -4rem;
  }

  .up-vertical-arrow-container {
    width: 100vw;
    position: fixed;
    bottom: 6rem;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: var(--main-font-primary);
      font-size: 1.4rem;
    }

    svg {
      position: relative;
      top: -1.5rem;
      transform: rotate(-90deg);
    }
  }

  .cart-item-inner {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    min-width: 100%;

    &.error {
      background-color: hsl(0, 0%, 85%) !important;
      border: solid 2px red !important;
    }
  }

  .cart-item {
    box-sizing: content-box;
    background-color: white;

    border-radius: 1rem;
    position: relative;
    background-color: white !important;

    &.error {
      background-color: hsl(0, 0%, 85%) !important;
      border: solid 2px red !important;
    }
    // border: solid 0.5px #e2e0e1;
    .button-remove-cart-item {
      color: var(--main-dark-font-color);
      border-radius: 100%;
      border: none;
      background-color: white;
      max-width: 2.4rem;
      max-height: 2.4rem;
      padding: 0;
      margin: 0;
      position: absolute;
      top: calc(- (100vh - 50%));
      right: calc(-1 * (30%));
      background-color: white;
      display: flex;
    }
  }
}

.cart-item-inner {
  display: flex;
  flex-direction: row;
  gap: 3rem;

  &.error {
    background-color: hsl(0, 0%, 85%) !important;
    border: solid 2px red !important;
  }
}

@media screen and (max-width: 376px) {
  .cart-item-details span {
    font-size: smaller;
  }
}

@media screen and (max-width: 321px) {
  .cart-item-details span {
    font-size: x-small;
  }
}

// the white background that appears behind cart-view-list
.cart-backdrop-cover {
  background-color: white;
  height: 100vh;
  overflow-y: scroll;
  z-index: -5;
  position: absolute;
  top: 0rem;
  left: 0;
}

/**
 Loading cart item
**/
.cart-item-loading {
  background-color: white !important;
  box-sizing: content-box;

  .cart-item-inner {
    gap: 1rem;

    &.error {
      background-color: hsl(0, 0%, 85%) !important;
      border: solid 2px red !important;
    }
  }

  .cart-image-loading {
    min-width: 8rem;
    min-height: 8rem;
    background-color: gray;
    border-radius: $border-radius;
    margin-right: 0 !important;
  }
  .cart-item-details {
    .cart-item-name {
      background-color: gray;
      min-width: 12rem;
      min-height: 1.8rem;
      border-radius: 0.3rem;
    }

    .input-amount-dial-holder,
    .cart-item-amount {
      background-color: gray;
      min-height: 1.8rem;
      border-radius: 0.3rem;
    }

    .cart-item-amount {
      min-width: 9.5rem;
    }

    .input-amount-dial-holder {
      margin-top: 1rem;
      min-width: 10.5rem;
    }
  }
}
