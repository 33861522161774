@import "../../abstracts//variables";

.singleProductSC {
  height: 17rem;
  border-radius: $border-radius;
  background-color: $main-white;
  box-shadow: $normal-box-shadow;
  width: 19%;
  // width: 17.8rem;
  // margin: 0.5rem 0rem;
  margin: 0.5rem;
  /*  padding: 1rem; */

  &.disabled {
    background-color: rgb(216, 216, 216) !important;
  }

  @media only screen and (max-width: 1070px) {
    width: 23.2%;
  }
  @media only screen and (max-width: 900px) {
    width: 31%;
  }
  @media only screen and (max-width: 600px) {
    width: 40%;
    margin: 0.5rem 0rem;
  }
  @media only screen and (max-width: 400px) {
    width: 48.5%;
    margin: 0.5rem 0rem;
  }

  @media only screen and (max-width: 350px) {
    width: 90%;
    height: 22rem;
  }

  &__top {
    position: relative;
    height: 8rem;
    width: 100%;
    overflow: hidden;
    /* display: flex;
    align-items: flex-start;
    justify-content: space-between; */

    @media only screen and (max-width: 350px) {
      height: 13rem;
    }

    &--img {
      height: 100%;
      width: 100%;
      overflow: hidden;
      // border-radius: $border-radius;

      img {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        height: 100%;
        width: 100%;
        object-fit: contain;
        // object-fit: cover;
      }
    }
    &--placeholder {
      position: relative;
      width: 100%;
      height: 8rem;
      filter: blur(10px);
      background: #dddddd53;
      transform: scale(1);
      animation: pulsate 700ms alternate infinite ease;
    }

    &--buttons {
      //   height: 100%;
      height: 100%;
      width: 4rem;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      &-minusBtn,
      &-plusBtn {
        position: absolute;
        z-index: 1;
        top: 0.5rem;
        right: 0.5rem;
        border: solid 1.8px #c3d3d4;
        border-radius: $border-radius;
        background-color: $main-white;
        height: 3rem;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 2.2rem;
          height: 2.2rem;

          @media only screen and (max-width: 350px) {
            height: 2.2rem;
            width: 2.2rem;
          }
        }

        @media only screen and (max-width: 350px) {
          height: 3.6rem;
          width: 3.6rem;
        }
      }

      p {
        font-size: 1.6rem;
        font-weight: 500 !important;
        color: $input-color;
        margin: 0rem;
        font-family: var(--main-font-secondary) !important;

        @media only screen and (max-width: 350px) {
          font-size: 2rem;
        }
      }
    }
  }

  &__bottom {
    /* display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column; */
    height: 40%;
    width: 100%;
    padding: 0 1rem 1rem 1rem;

    &--textHolder {
      height: 4rem;
      margin-bottom: 1rem;
      overflow: hidden;
      h3 {
        margin: 0rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-weight: 400 !important;
        font-size: 1.4rem;
        color: $main-black;
        font-family: var(--main-font-primary) !important;
      }
    }

    span {
      margin-bottom: 0rem;
      font-weight: 500 !important;
      font-size: 1.5rem;
      color: $main-black;
      font-family: var(--main-font-primary) !important;
    }
  }
}
