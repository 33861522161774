@import "../abstracts/variables";

.headingExpandable {
  border-top: 2px solid $main-light-gray;
  padding-top: 1.75rem;
  padding-bottom: 1rem;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: var(--main-dark-font-color);
      font-family: var(--main-font-primary);
      font-weight: 400 !important;
      font-size: 1.75rem !important;
      margin: 0;
    }

    svg {
      height: 1.75rem;
      width: 1.75rem;
      cursor: pointer;
      fill: $main-gray;
      transition: $normal-transition;

      &:hover {
        fill: var(--main-dark-font-color);
      }
    }
  }

  &__bottom {
    overflow: hidden;
    height: 100%;
    transition: $normal-transition;

    &--expanded {
      height: 6rem;
    }
  }
}
