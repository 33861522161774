.theme1-productPrice {
  border-top: 2px solid var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
  padding: 0.2rem 0;
  width: 100%;
  font-weight: 600 !important;
  letter-spacing: -0.2px;
  line-height: 140%;
  font-size: 2.2rem;
  color: var(--theme-color);
  font-family: var(--main-font-primary);
  margin-top: 2rem;
}

.theme1-productDescription {
  width: 100%;
  font-weight: 400 !important;
  font-size: 1.4rem;
  font-family: var(--main-font-primary);
  color: gray;
  margin-top: 0.5rem;
  // margin-top: 2rem;
}
