.input-amount-with-controls {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  font-size: 1.3rem;

  $button-light-grey: #878787;

  button {
    margin: 0 1rem;
    border: solid 0.5px $button-light-grey;
    text-align: center;
    padding: 0.3rem;
    padding-top: 0;
    color: $button-light-grey;
    background-color: white;
    border-radius: $border-radius;
    aspect-ratio: 1/ 1;
    min-width: 2.2rem;
    max-height: 2.2rem;
  }

  span {
    font-size: 1.3rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1rem;
    max-width: 1rem;
    text-align: center;
    color: $button-light-grey;
  }
}

@media screen and (max-width: 310px) {
  .input-amount-with-controls {
    button {
      height: 3rem;
      font-size: 2rem;
    }
  }
}
