@import "../../abstracts/variables.scss";

.profilePageNameNumber {
  /*   padding: $title-area-padding;
  margin-top: 2rem;
  margin-bottom: 2rem; */

  &__name {
    display: flex;
    h2 {
      font-size: 2.2rem !important;
      font-weight: 700 !important;
      color: $main-black;
      font-family: var(--main-font-primary);
      margin-bottom: 1rem !important;
    }
  }

  &__number {
    p {
      font-size: 1.8rem !important;
      font-weight: 400 !important;
      color: $main-black;
      margin: 0rem;
      font-family: var(--main-font-secondary) !important;
    }
  }
}
