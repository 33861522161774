@import "../../abstracts/variables";

.poweredByTheme2 {
  min-height: 3.5rem;
  // min-width: 14rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 375px) {
    width: 14rem;
  }

  &__right {
    height: 2.2rem;
    width: auto;
    margin-left: 0.9rem;
    img {
      height: 100%;
      width: auto;
    }
  }

  &__left {
    font-family: var(--main-font-primary);
    padding-bottom: 0.2rem;
    text-align: right;
    span {
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 0.7rem;
      font-weight: 600 !important;
      color: $main-black;
      line-height: 0.8rem;
    }

    h3 {
      margin-bottom: 0;
      margin-top: 0.2rem;
      font-size: 0.95rem;
      line-height: 1rem;
      font-weight: 700 !important;
      color: $main-black;
    }
  }
}
