.buckaroo-button {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font-primary);
  font-weight: bold !important;
  background-color: black;
  padding: 0.4em;
  width: 100%;
  color: white;
  border-radius: var(--button-border-radius);
  -webkit-box-shadow: 0px 10px 11px -11px #000000;
  box-shadow: 0px 10px 11px -11px #000000;
  font-size: 45px;
  cursor: pointer;
}
.change-pay-method-link {
  position: relative;
  font-family: var(--main-font-secondary);
  color: #004bf9dc;
  cursor: pointer;
}

.apple-pay-button {
  font-size: large;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain; /* Use any supported button type. */
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
  -apple-pay-button-padding: 0px 0px;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
  -apple-pay-button-padding: 0px 0px;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
  -apple-pay-button-padding: 0px 0px;
}
