.phone-number-with-country-modal {
  z-index: 1;
  // height: 6rem;
  max-width: 100%;
  font-size: 1.4rem;
  position: relative;
  font-family: var(--main-font-primary);
  // background-color: white;
  transition: all 0.3s ease;

  .form-label-phone {
    color: #ababab;
    font-size: 1.1rem;
    font-weight: 400 !important;
    // margin-top: 0.8rem;
    margin-top: 0.4rem !important;
  }

  .phone-input-field-container {
    height: 6rem;
    border: solid 1px #c3d3d4 !important;
    border-radius: $border-radius;
    position: relative;
    padding: 1rem;
    width: 100%;
    overflow: hidden;
    background-color: white;
    /*  border-bottom: solid 1px #fff;
    border-bottom-right-radius: $border-radius !important; */

    transition: $normal-transition;
    &:focus-within {
      border: solid 1px #1890ff !important;
    }

    &[data-phone-number-with-country-modal-is-disabled="true"] {
      & input {
        cursor: not-allowed;
      }
    }

    &__required {
      border: solid 1px rgb(196, 71, 71) !important;
      border-radius: $border-radius !important;
      /* label {
        color: rgb(196, 71, 71) !important;
      } */
    }
  }

  .phone-number-inputs {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: $input-height;
    flex-grow: 1;
  }

  .phone-number-input-field {
    padding-top: 1.4rem;
    text-align: left;
    width: 100%;
    font-size: $input-font-size;
  }

  .phone-number-with-country-inner {
    display: flex;
    height: 6rem;
    align-items: center;
    padding: 0;
    font-size: 1.8rem;
    border: none;
    max-height: 6rem;
    width: 100%;
  }

  .country-flag-holder {
    background-color: white;
    margin-right: 1.5rem;
    overflow: hidden;
    border: solid 1px #c3d3d4 !important;
    border-radius: $border-radius;
    height: 6rem;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.26rem;
    cursor: pointer;
    text-align: center;
  }

  input {
    border: none !important;
    overflow-y: auto;
  }

  input:disabled {
    background-color: var(--input-disabled-bg-color) !important;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  .verification-alert-container {
    right: 1.6rem;
    position: absolute;
  }

  .verification-alert {
    cursor: pointer;
    font-size: 1rem !important;
    padding: 0.5rem 1rem;
    padding-top: 0.65rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    height: 1.8em;
    // max-height: 1.8em;
    width: 10rem;
    background-color: var(--verify-alert-red);
    color: #00000000;
    font-weight: 800 !important;
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 1px 0px 0px rgb(229, 229, 229);
    box-shadow: 0px 2px 6px 0px rgba(217, 46, 16, 0.292);
    animation: ballToHorizontalPill 0.23s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
}

.create-adup-account-slider {
  height: 8rem;
  max-width: 100%;
  border: solid 1px #c3d3d4 !important;
  border-radius: $border-radius;
  font-size: 1.4rem;
  position: relative;
  font-family: var(--main-font-primary);
  background-color: white;
  transition: all 0.3s ease;
}

/* .roundButton {
  float: right;
  button {
    border: none;
    background-color: transparent;
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.78);
    transition: $normal-transition;
    color: #fff;
    img {
      transition: $normal-transition;
      height: 75%;
      width: 120%;
    }
  }
  button:active {
    box-shadow: none;
  }
} */

/*=================== country search style ====================*/

.modal-backdrop-fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.515);
  z-index: 30;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.4rem);
}

.phone-number-search-modal-container {
  background-color: transparent;
  position: fixed;
  width: 60vw;
  height: 50vh;
  z-index: 90;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.2rem;
  overflow: hidden; /* This controls the overflow of the modal container */
}

.phone-number-search-modal {
  font-family: var(--main-font-primary);
  font-size: 1.3rem;
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the modal takes full height */

  .searchInput-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
  }

  .searchInput-title {
    padding-right: 0.2rem;
    font-family: var(--main-font-primary);
    h1 {
      color: $main-white;
      font-weight: 400 !important;
      font-size: 2.3rem;
    }
  }

  .searchInput-close-button {
    cursor: pointer;
  }

  .countries-list-container {
    background-color: white;
    border-radius: 1.2rem;
    // border: #c3d3d4 solid 2px;
    flex: 1; /* Make this area take up the remaining space */
    overflow: hidden; /* Prevent scrolling on this container */
    display: flex;
    flex-direction: column; /* Ensure children are arranged in a column */
    border: #c3d3d4 solid 2px;
  }

  input[type="search"] {
    width: 100%;
    padding: 1.5rem;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    border-bottom: solid 1px #c3d3d4 !important;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    border: none;
    transition: all 0.3s ease;
    font-size: $input-font-size;
    color: $input-color;
    background-color: #fff;

    &:focus {
      outline: none;
      border-bottom: $input-border-focus-small !important;
    }
  }

  .countries-list-holder {
    margin-top: 0rem;
    flex: 1; /* Ensure it takes up remaining space */
    overflow: hidden; /* Prevent scrolling on this container */
  }

  ul.countries-list {
    list-style: none;
    height: 100%; /* Fill the height of the parent container */
    overflow-y: auto; /* Enable vertical scrolling */
    margin: 0;
    padding: 0;
  }

  ul.countries-list li {
    border-bottom: solid 1px rgba(211, 211, 211, 0.37);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  .list-selected-country {
    background-color: #c3d3d4;
  }
}

.phone-number-with-country-modal-wrapper {
  width: 100%;
  /* margin-bottom: 1.6rem; */
  * {
    border: none;
  }
  /* border: solid 1px #c3d3d4; */
  /* border-radius: $border-radius;

  -webkit-box-shadow: 0px 1px 0px 0px rgba(229, 229, 229, 0.93);
  box-shadow: 0px 1px 0px 0px rgba(229, 229, 229, 0.93); */
}

@media screen and (orientation: landscape) {
  .phone-number-search-modal-container {
    height: 80vh;
    width: 80vw;
  }
}

@media only screen and (max-width: 750px) {
  .phone-number-search-modal-container {
    width: 80vw;
  }
}
