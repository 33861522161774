.verify-phone-number {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1000;

  .backdrop {
    margin: 0;
    padding: 0;
    top: 0;
    min-width: 100vw;
    min-height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .verify-phone-number-prompt {
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 1.5rem 1.5rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.2rem;
    box-sizing: border-box;

    /*  * {
      text-align: center;
    } */

    &-container {
      width: 40rem;
      padding: 3rem;
      padding-top: 0;
      @media only screen and (max-width: 400px) {
        width: 100%;
      }
      p {
        text-align: left !important;
      }
    }

    .verification-methods {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    h1 {
      font-family: var(--main-font-primary);
      margin-bottom: 0;
      color: $main-black;
      font-weight: 700 !important;
      font-size: 2.1rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.7rem;
      line-height: 2.7rem;
      margin-bottom: 1.4rem;
      color: $main-black;
      text-align: center;
      font-weight: 400 !important;
      font-family: var(--main-font-secondary) !important;
      /* margin-bottom: 0; */
      margin-top: 1rem;
    }

    .learn-more-link {
      font-size: 1.6rem;
      font-family: var(--main-font-primary);
      font-style: normal;
      font-weight: 600 !important;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: center;
      color: $main-blue-hover;
      text-decoration: none;
      cursor: pointer;
    }

    .send-again-link {
      margin: 1.5rem 0 0.5rem 0;
      font-size: 1.6rem;
      font-family: var(--main-font-primary);
      font-style: normal;
      font-weight: 600 !important;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: left;
      color: $main-black;
      text-decoration: none;
      cursor: pointer;
    }

    button {
      height: 6rem;
      font-family: var(--main-font-primary);
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: left;
      width: 100%;
      border-radius: $border-radius;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      max-width: 33.5rem;
      color: var(--main-dark-font-color);

      .verifying-now-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .webAuthn-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        color: rgb(58, 148, 239);
      }

      /* svg {
        position: absolute;
        height: 10.5rem;
        top: -0.3rem;
        left: 105%;
      } */
    }

    .btn-use-email {
      background-color: white;
      border: solid 1px var(--main-dark-font-color);
    }
    .btn-use-accept {
      background-color: $nav-back-color;
      color: $nav-font-color;
      border: solid 1px var(--main-dark-font-color);
    }

    .lighten-button {
      opacity: 0.6;
    }

    .btn-send-sms {
      background-color: var(--theme-teal-bright);
      border: none;

      -webkit-box-shadow: 0rem 0.1rem 0.8rem -0.2rem #2af3cc;
      box-shadow: 0rem 0.1rem 0.8rem -0.2rem #2af3cc;
    }
  }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop {
    background-color: rgba(0, 0, 0, 0.4) !important;
    -webkit-backdrop-filter: blur(1.2em) !important;
    backdrop-filter: blur(1.2em) !important;
  }

  .svg-backdrop-firefox-IE {
    display: none;
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop {
    background-color: rgba(0, 0, 0, 0.6) !important;
    -webkit-backdrop-filter: blur(1.2em) !important;
    backdrop-filter: blur(1.2em) !important;
  }
}

.otp-verify-phone {
  height: 7.5rem;
  display: flex;
  width: 100%;
}

.otp-inputs-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:focus {
    border: $input-border-focus-small;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Firefox */
  input[type="text"] {
    -moz-appearance: textfield;
  }
}

@media screen and (min-width: 375px) {
  .otp-verify-phone {
    justify-content: center;
  }

  .otp-inputs-holder {
    max-width: 33.5rem;
    gap: 1rem;
  }
}

.verifyLater {
  color: inherit;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  &__span1 {
    margin-right: 0.5rem;
  }

  &__span2 {
    color: inherit;
    text-decoration: none;
    font-weight: 800 !important;
    cursor: pointer;
    transition: $normal-transition;

    &:hover {
      text-decoration: underline;
    }
  }
}
