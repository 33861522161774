.compactCart {
  height: 4rem;
  /*  border: solid 1px $input-border-light-blue; */
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  padding: 1rem 1.2rem;
  background-color: $main-white;
  position: relative;

  &[data-compact-cart-open="true"] {
    .compactCart__items--arrow svg {
      transition: all 0.3s ease;
      transform: rotate(90deg);
    }
  }
  &[data-compact-cart-open="false"] {
    .compactCart__items--arrow svg {
      transition: all 0.3s ease;
      transform: rotate(270deg);
    }
  }

  &.error {
    border: solid 2px red !important;
  }

  &__count {
    background-color: $input-color;
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.7rem;
    left: -1rem;
    box-shadow: $normal-box-shadow-plus;
    overflow: hidden;
    z-index: 101;

    span {
      color: $main-white;
      font-size: 1.1rem;
      font-weight: 400 !important;
      margin: 0;
    }
    &.error {
      background-color: red !important;
    }
  }

  &__itemsHidden {
    height: 100%;
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;

    &--cart {
      height: 100%;
      display: flex;
      align-items: center;
      svg {
        height: 2rem;
        width: 2rem;
        fill: $input-color;
      }
    }

    &--amount {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 1rem;
      h2 {
        color: $main-black;
        font-size: 2rem;
        font-weight: 500 !important;
        margin: 0;
        line-height: 2rem;
      }
    }

    &--arrow {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: $input-color;
        transform: rotate(270deg);
      }
    }
  }

  &__items {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 100;
    padding: 1rem 1.2rem;
    background-color: $main-white;
    /*   border: solid 1px $input-border-light-blue; */
    border-radius: $border-radius;
    right: 0.1rem;
    &--cart {
      height: 100%;
      display: flex;
      align-items: center;
      svg {
        height: 2rem;
        width: 2rem;
        fill: $input-color;
      }
    }

    &--amount {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 1rem;
      h2 {
        color: $main-black;
        font-size: 2rem;
        font-weight: 500 !important;
        margin: 0;
        line-height: 2rem;
      }
    }

    &--arrow {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: $input-color;
        transform: rotate(270deg);
      }
    }
  }

  &__dropdown {
    max-height: calc(100vh - 6rem);
    padding: 1rem;
    overflow-y: scroll;
    width: 42vw;
    background-color: $main-white;
    /*  border: solid 1px $input-border-light-blue; */
    box-shadow: $normal-box-shadow-plus;
    // box-shadow: 0px 5px 100vh 100vw rgba(0, 0, 0, 0.35);
    border-radius: $border-radius;
    position: absolute;
    top: 5rem;
    right: 0;
    z-index: 100;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    @media only screen and (max-width: 750px) {
      width: 92vw;
    }
  }

  &__overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.35);
    cursor: pointer;
  }
}
