.new-payment-method-icon {
  display: flex;
  align-items: center;
  text-align: left;
  text-transform: capitalize;
  position: relative;
  height: 5.6rem;
  font-weight: 300 !important;
  transition: all 0.3s ease;
  cursor: pointer;

  &[data-simple-payment-layout="2"] {
    img {
      margin-right: 1rem;
    }
  }

  span {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: left;
  }

  img {
    width: 3rem;
    min-width: 3rem;
    margin-right: 3rem;
  }

  svg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    max-height: 100%;
    position: absolute;
    padding-top: 1.3rem;
    left: 0;

    circle {
      position: absolute;
      height: 2rem;
    }
  }

  &.selected-payment-method {
    background-color: rgb(220, 236, 255) !important;
    border: solid 1px #1890ff !important;
  }
}

.new-payment-method-with-extras {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: solid #c3d3d4 1px;
  cursor: pointer;
  transition: height 0.3s ease;

  &[data-option-payment-layout="2"] {
    img {
      margin-right: 1rem;
    }
  }

  .payment-method-icon-and-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 5.6rem;
    // padding: 1.1rem;
  }

  img {
    width: 3rem;
    min-width: 3rem;
    margin-right: 3rem;
  }

  svg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    max-height: 100%;
    position: absolute;
    padding-top: 1.3rem;
    left: 0;

    circle {
      position: absolute;
      height: 2rem;
    }
  }

  .new-payment-method-options {
    display: none;
  }

  &.selected-payment-method {
    border: solid 1px #1890ff;
    height: 12rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
    .payment-method-icon-and-name {
      background-color: rgb(220, 236, 255);
      padding: 1.1rem;
      border-bottom: 0.5px solid rgb(118, 174, 243);
    }

    .new-payment-method-options {
      padding: 1.1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .new-payment-method-options-list-name {
      font-size: 1.1rem;
      font-weight: 400 !important;
      color: #9a9a9a;
    }

    .payment-method-option-selector {
      margin-top: 0.6rem;
      border: none;
      font-size: 1.6rem;
      font-weight: 100 !important;

      outline: none !important;
    }
  }

  .payment-method-option-selector {
  }
}
