.otp-inputs-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    background-color: #ffffff;
    color: var(--main-text-color);
    height: 7rem;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    /* aspect-ratio: 1/ 1; */
    text-align: center;
    font-size: 3rem;
  }

  input:focus {
    border: 1px solid #cecece;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

@media screen and (min-width: 375px) {
  .otp-verify-phone {
    justify-content: center;
  }

  .otp-inputs-holder {
    max-width: 33.5rem;
    gap: 1rem;
  }
}

.light-gray-border {
  border: solid 1px #c3d3d4;
  border-radius: 0.8rem;

  width: 100%;
  outline: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 1px 0px 0px rgb(229, 229, 229);
  box-shadow: 0px 1px 0px 0px rgba(229, 229, 229, 0.93);
}
