@import "../../abstracts/variables";

.userSummaryTheme2 {
  box-sizing: border-box;
  object-fit: contain;
  //   border: solid 1px #1890FF;
  border-radius: $border-radius;
  font-family: var(--main-font-primary);
  background-color: white;

  .flex-col {
  }

  &__paymentMethodSummary {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    text-transform: capitalize;
  }

  &__paymentMethodIcon {
    max-height: 1.6rem;
  }

  p,
  h3 {
    margin: 0.4rem 0;
  }

  p {
    font-weight: 400 !important;
    font-family: var(--main-font-secondary) !important;
  }

  h3 {
    color: var(--main-dark-font-color);
    font-weight: 500 !important;
    font-size: 1.2rem;
    // text-transform: uppercase;
  }

  &__userPhoneSummary {
    font-size: 1.2rem;
    font-weight: 200 !important;
    color: #2a2a2a;
    font-family: var(--main-font-secondary) !important;
  }

  &__summarizedFastcheckoutSegment {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.4rem 0rem;
    border-top: 2px solid $main-light-gray;
    max-height: 7rem;
    height: 7rem;
    // position: relative;

    &:first-of-type {
      border-top: none;
    }

    /* .editButton-component {
        border: none;
        background-color: transparent;
        position: absolute;
        right: 1rem;
  
        // @media only screen and (max-width: 400px) {
        //   right: 3rem;
        // }
      } */

    /* .deleteButton-component {
        button {
          border: none;
          background-color: transparent;
          position: absolute;
          right: 3rem;
  
          @media only screen and (max-width: 400px) {
            right: 3rem;
          }
        }
      } */
  }
}

.heading-with-button-to-right {
  img {
    max-height: 4rem;
  }
}
