@import "../../abstracts/variables";

.singleProductTheme2 {
  position: relative;
  height: 21rem;
  border-radius: $border-radius;
  background-color: $main-white;
  box-shadow: $normal-box-shadow;
  width: 19%;
  // width: 17.8rem;
  // margin: 0.5rem 0rem;
  margin: 0.5rem;
  /*  padding: 1rem; */

  &-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 2rem;
      font-weight: 500;
      color: $main-white;
    }
  }

  /* &.disabled {
    background-color: rgb(216, 216, 216) !important;
  } */

  @media only screen and (max-width: 1070px) {
    width: 23.2%;
  }
  @media only screen and (max-width: 900px) {
    width: 31%;
  }
  @media only screen and (max-width: 600px) {
    width: 40%;
    margin: 0.5rem 0rem;
  }
  @media only screen and (max-width: 400px) {
    width: 48.5%;
    margin: 0.5rem 0rem;
  }

  @media only screen and (max-width: 350px) {
    width: 90%;
    // height: 22rem;
  }

  &__top {
    display: flex;
    flex-direction: column;
    height: 13rem;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    /*  @media only screen and (max-width: 350px) {
      height: 13rem;
    } */

    &--star {
      width: 1.7rem;
      height: 1.7rem;
      align-self: flex-end;

      svg {
        height: 100%;
        width: 100%;
        fill: transparent;
      }
    }

    &--img {
      height: 100%;
      width: 100%;
      overflow: hidden;
      // border-radius: $border-radius;

      img {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        height: 100%;
        width: 100%;
        object-fit: contain;
        // object-fit: cover;
      }
    }
    &--placeholder {
      width: 100%;
      height: 8rem;
      filter: blur(10px);
      background: #dddddd53;
      transform: scale(1);
      animation: pulsate 700ms alternate infinite ease;
    }
  }

  &__bottom {
    height: 40%;
    width: 100%;
    padding: 0 1rem;

    &--textHolder {
      height: 2.5rem;
      margin-bottom: 0rem;
      overflow: hidden;
      h3 {
        margin: 0rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-weight: 400 !important;
        font-size: 1.4rem;
        color: $main-black;
        font-family: var(--main-font-primary) !important;
      }
    }

    &--currency {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        margin-bottom: 0rem;
        font-weight: 500 !important;
        font-size: 1.5rem;
        color: $main-black;
        font-family: var(--main-font-primary) !important;
      }

      div {
        background-color: $main-white;
        border: 1px solid #6d6d6d;
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        transition: $normal-transition;

        &:hover {
          border-color: var(--theme-color);

          svg {
            fill: var(--theme-color);
          }
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
          fill: #6d6d6d;
        }
      }
    }
  }
}
