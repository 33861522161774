@import "../abstracts/variables";

.deleteCartMsg__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.515);
  z-index: 1000 !important;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.4rem);
  -webkit-backdrop-filter: blur(0.4rem);
  -moz-backdrop-filter: blur(0.4rem);
}

.deleteCartMsg__wrapper {
  background-color: #f3f3f3;
  position: fixed;
  max-width: 60vw;
  padding: 2rem;
  max-height: 50vh;
  z-index: 103;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .deleteCartMsg-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.2rem;
    h1 {
      margin-bottom: 0.5rem;
      font-size: 2.5rem;
      text-align: center;
      color: $main-black;
      font-weight: 400 !important;
    }
  }

  .deleteCartMsg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin: 1rem 0rem 2rem 0rem;
    /* background-color: $main-light-gray;
      border-radius: $border-radius; */

    .textContent {
      text-align: center;
      // background-color: $main-light-gray;
      width: 100%;
      padding: 2rem;
      font-size: 1.5rem;
      font-weight: 500 !important;
      color: $main-gray;
      // border-bottom: 1.7px solid rgb(171, 171, 171);
      /*    border-top: $input-border-normal;
        border-right: $input-border-normal;
        border-left: $input-border-normal;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius; */
    }

    .textContent-top,
    .textContent-bottom {
      text-align: center;
      // background-color: $main-light-gray;
      width: 100%;
      padding: 0.2rem 1rem;
      font-size: 1.5rem;
      font-weight: 400 !important;
      color: $main-gray;
      /* border-right: $input-border-normal;
        border-left: $input-border-normal;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius; */
    }

    .textContent-top {
      padding-top: 2rem;
      border-top: $input-border-normal;
    }
    .textContent-bottom {
      // border-bottom: 1.7px solid rgb(171, 171, 171);
      padding-bottom: 2rem;
      font-weight: 600 !important;
    }

    .buttonContainer {
      width: 100%;
      display: flex;

      .btn1,
      .btn2 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 2rem;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: 600 !important;
      }
      .btn1 {
        flex: 1;
        color: $main-white;
        background-color: $main-red;
      }
      .btn2 {
        flex: 1;
        color: $main-black;
        background-color: #d1d1d1;
      }
    }
  }
}

/* @media screen and (orientation: landscape) {
  .deleteCartMsg__wrapper {
    // min-height: 80vh;
    min-width: 80vw;
  }
} */

@media only screen and (max-width: 750px) {
  .deleteCartMsg__wrapper {
    min-width: 80vw;
  }
}
