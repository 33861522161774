@import "../../abstracts/variables";

.theme1-order-cart-summary {
  background-color: white;
  // color: #2a2a2a;
  color: var(--main-dark-font-color);
  font-weight: 100 !important;
  position: relative;
  width: 100%;
  border: solid 1px #c9d8d9;
  border-radius: $border-radius;
  box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);

  .theme1-order-amounts {
    max-width: 100%;
    padding: 1.82em;
    padding-bottom: 0;
  }

  .theme1-order-amount-field {
    position: relative;
    width: 100%;
    font-size: 1.5rem;
    font-family: var(--main-font-primary);
    font-style: normal;
    font-weight: 400 !important;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 1.5rem;

    div {
      position: absolute;
      right: 0;
      top: 0;
      // color: black;
      color: var(--main-dark-font-color);
    }

    .skeleton-loader {
      position: absolute;
      right: 0;
      top: 10%;
      max-width: 15%;
    }
  }

  .theme1-order-total-holder {
    display: flex;
    font-family: var(--main-font-primary);
    font-size: 1.6rem;
    font-weight: 700 !important;
    margin: 2rem 0;
    text-align: left;

    div {
      position: absolute;
      font-weight: 700 !important;
      right: 2rem;
      color: var(--main-dark-font-color);
    }

    .skeleton-loader {
      font-size: 1.6rem;
      right: 2rem;
      font-weight: 500 !important;
      position: absolute !important;
      max-width: 15%;
    }
  }

  hr {
    border: solid 0.01px #d7d7d7;
    height: 0.1px;
    margin: 0;
  }

  .theme1-order-summary-heading {
    font-family: var(--main-font-primary);

    h5 {
      font-size: 1.6rem;
      font-weight: 700 !important;
      margin: 0;
      margin-bottom: 2rem;
    }
  }
}
