@import "../../sass/abstracts/variables";
body {
  font-family: var(--main-font-secondary);
  font-size: 16px;
  background: #e6f1ff;
}

.PaymentForm {
  display: flex;
  margin: 40px auto 0;
  width: 80%;
  position: relative;
  &__Spinner {
    position: absolute;
    padding-left: 40vw;
    padding-right: 40vw;
    padding-top: 180px;
    padding-bottom: 180px;
    transform: translate(-50, -50);
    background-color: $background-color;
    opacity: 75%;
    z-index: 1;
    font-size: small;
  }
}

.PaymentForm form {
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
}

label {
  opacity: 0.6;
  font-size: 18px;
  padding-bottom: 7px;
  padding-top: 13px;
  font-weight: 500 !important;
  display: block;
}

.mollie-component {
  background: #fff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  padding: 13px;
  border: 1px solid transparent;
  transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500 !important;
}

.mollie-component.has-focus {
  border-color: #0077ff;
  transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
}

.mollie-component.is-invalid {
  border-color: #ff1717;
  transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
}

.field-error {
  font-size: 12px;
  margin-top: 2px;
  color: #ff1717;
  font-weight: 400 !important;
}

button.submit-button {
  width: 100%;
  background-color: $background-color;
  border: 0;
}

.form-fields {
  margin-bottom: 24px;
}

.form-group {
  width: 100%;
}

.form-group--expiry-date {
  /* margin-right: 8px; */
}

.form-group--verification-code {
  /* margin-left: 8px; */
}
