@import "../abstracts/variables";

:root {
  --fast-checkout-page-flex-gap: 4.5rem;
  --fast-checkout-page-segment-gap: 1.8rem;
}

.header-profile-icon {
  float: right;
  top: 3.2rem;
  right: 1.8rem;
  border: solid 1px #626262;
  border-radius: 50%;
  padding: 0.8rem;
  position: absolute;
}

.heading-medium-light {
  font-weight: 300 !important;
  color: var(--main-dark-font-color);
  font-family: var(--main-font-primary);
  text-transform: capitalize;
  margin-block-end: 1.6rem;
}

.cart-view-holder {
  box-sizing: border-box;
  display: flex;
  min-width: 100%;
  max-width: 100%;
  left: 0;
  z-index: 20;
  padding: 0;
  position: relative;
  min-height: 13rem;
  margin-bottom: 0.5rem; //remove to reduce gap

  .cart-view {
    /* max-height: 1rem; */
    width: 100%;
  }

  .cart-view:first-child {
    min-width: 100% !important;
  }
}

.fast-checkout-page {
  padding: 2rem 4rem;
  position: relative;

  @media only screen and (max-width: 550px) {
    padding: 1.8rem;
  }

  /* These styles are only used to set checkout-background-color styles ----start*/
  &[data-checkout-bg-color="true"] {
    .main-right-content-holder-user-details {
      padding: 0.4rem 2rem 2rem 2rem;
      border-radius: 0.7rem;
      box-shadow: $normal-box-shadow;
      @media only screen and (max-width: 750px) {
        margin-top: 3.2rem;
        // margin-bottom: 1.6rem;
        padding: 0rem 2rem 2rem 2rem;
        border-radius: 0.7rem;
        box-shadow: $normal-box-shadow;

        .account-phone-heading-holder {
          margin-top: 0.4rem !important;
        }
      }
    }

    /* .main-left-content-holder {
      padding-top: 0.4rem !important;
    } */

    .billing-is-shipping-content {
      margin-bottom: 0 !important;
    }

    .order-summary-section {
      padding: 0.4rem 2rem 0.6rem 2rem;
      border-radius: 0.7rem;
      box-shadow: $normal-box-shadow;
      @media only screen and (max-width: 750px) {
        margin-top: 3.2rem;
        // margin-bottom: 1.6rem;
        padding: 0rem 2rem 2rem 2rem;
        border-radius: 0.7rem;
        box-shadow: $normal-box-shadow;
      }
    }

    .payment-section {
      margin-top: 3.2rem;
      padding: 0.4rem 2rem 0.8rem 2rem;
      border-radius: 0.7rem;
      box-shadow: $normal-box-shadow;
      @media only screen and (max-width: 750px) {
        padding: 0rem 2rem 0.8rem 2rem;
        border-radius: 0.7rem;
        box-shadow: $normal-box-shadow;
      }
    }
  }
  /* These styles are only used to set checkout-background-color styles ----end*/

  .fast-checkout-page-top {
    position: relative;
    max-width: 100%;
    max-height: min-content;

    .heading-with-button-to-right {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__left {
        display: flex;
        align-items: center;

        .back-button {
          /* position: absolute;
        top: 1.8rem; */
          /* margin-bottom: 0.2rem; */
          margin-bottom: -0.2rem;
          @media only screen and (max-width: 400px) {
            margin-bottom: -0.3rem;
          }
          cursor: pointer;
          svg {
            max-height: 1.4rem;
            margin-top: 0.2rem;
          }

          & + h2 {
            margin-left: 2rem;
            /*  margin-bottom: 1.8rem; */
          }
        }
      }

      &__right {
        margin-top: 0.8rem;
      }
    }
  }

  .pay-button-black {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--main-font-primary);
    font-weight: bold !important;
    // background-color: black;
    background-color: var(--pay-button-color);
    bottom: 0rem;
    padding: 0.6em;
    width: 99%;
    color: $nav-font-color;
    border-radius: $border-radius;
    -webkit-box-shadow: 0rem 1rem 1.1rem -1.1rem #000000;
    box-shadow: 0rem 1rem 1.1rem -1.1rem #000000;
    font-size: 1.8rem;
    position: fixed;
    height: 5.6rem;

    @media only screen and (max-width: 290px) {
      bottom: 0.9rem !important;
    }
    /* margin-left: auto;
    margin-right: auto; */

    /* @media only screen and (max-width: 400px) {
      width: 92%;
    } */

    b {
      top: 1rem;
      position: absolute;
      left: 1.8rem;
    }
    b[data-display-title="false"] {
      top: 1.6rem;
    }

    p {
      position: absolute;
      left: 1.8rem;
      font-size: 1rem;
      font-weight: 250 !important;
      top: 2rem;
      color: $nav-font-color;
      font-family: var(--main-font-secondary) !important;
    }

    .animated-arrow-holder {
      right: 1.8rem;
      position: absolute !important;
      @media only screen and (max-width: 400px) {
        top: 0.9rem !important;
      }
      @media only screen and (max-width: 360px) {
        top: 0.6rem !important;
      }
    }
    .animated-arrow-right {
      stroke: $nav-font-color;
    }

    &.disabled {
      background-color: rgba(120, 120, 120, 0.893) !important;
      color: rgba(0, 0, 0, 0.256) !important;
      .animated-arrow-right {
        stroke: rgba(0, 0, 0, 0.256) !important;
      }
      p {
        color: rgba(0, 0, 0, 0.256) !important;
      }
    }
  }

  .pay-button-apple-pay {
    z-index: 50;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--main-font-primary);
    font-weight: bold !important;
    // background-color: black;
    background-color: black;
    bottom: 0rem;
    padding: 0.6em;
    width: 99%;
    color: white;
    border-radius: $border-radius;
    -webkit-box-shadow: 0rem 1rem 1.1rem -1.1rem #000000;
    box-shadow: 0rem 1rem 1.1rem -1.1rem #000000;
    font-size: 1.8rem;
    position: fixed;
    height: 5.6rem;
    /* margin-left: auto;
    margin-right: auto; */

    /* @media only screen and (max-width: 400px) {
      width: 92%;
    } */

    b {
      top: 1rem;
      position: absolute;
      left: 1.8rem;
    }

    p {
      position: absolute;
      left: 1.8rem;
      font-size: 1rem;
      font-weight: 250 !important;
      top: 2rem;
      color: $nav-font-color;
      font-family: var(--main-font-secondary) !important;
    }

    .animated-arrow-holder {
      right: 1.8rem;
      position: absolute !important;
    }
    .animated-arrow-right {
      stroke: white;
    }
  }

  .pay-button-holder-fixed {
    position: fixed;
    z-index: 14;
    box-sizing: border-box;
    bottom: 4rem;
    display: flex;
    flex-direction: column;
    // width: calc(100% - 36px);
    // width: 36.5rem;
    width: 36.5rem;
    /* left: 18px; */
    align-items: center;
    justify-content: center;
    // max-width: 335px;
    height: 6rem;

    /* top: 50%; */
    left: 50%;
    transform: translate(-50%, 30px) !important;

    @media only screen and (max-width: 750px) {
      width: 89%;
    }
    @media only screen and (max-width: 600px) {
      width: 86%;
    }
    @media only screen and (max-width: 530px) {
      width: 83%;
    }
    @media only screen and (max-width: 450px) {
      width: 82%;
    }
    @media only screen and (max-width: 400px) {
      width: 92%;
      bottom: 5rem;
    }
    @media only screen and (max-width: 370px) {
      bottom: 5rem;
    }

    /* @media screen and (orientation: landscape) {
      
    } */
  }

  .order-summary-amount-field {
    font-family: var(--main-font-primary) !important;
  }
}

.fastcheckout-page-container-flex {
  display: flex;
  flex-direction: column;

  // this is a main segment of the AI checkout page
  // e.g. account segment, order segment
  // these segments need to have an even spacing (margin-bottom) value
  .fast-checkout-page-segment {
    // margin-bottom: var(--fast-checkout-page-segment-gap);
  }
}

.overflow-hidden-for-scroll {
  overflow: hidden;
}

.nav-container {
  min-height: 5rem;
  width: 1px;
  position: relative;
}

.shipping-address-form-title[data-shippingeditingiconsvisible="false"]
  .editButton-component,
.shipping-address-form-title[data-shippingeditingiconsvisible="false"]
  .deleteButton-component {
  button {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
}

.billing-address-form-title[data-billingeditingiconsvisible="false"]
  .editButton-component,
.billing-address-form-title[data-billingeditingiconsvisible="false"]
  .deleteButton-component {
  button {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
}

.order-summary-heading {
  display: flex;
  align-items: center;
  svg {
    fill: $main-black;
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
  }
}
