@import "../../abstracts/variables";

.variantSelector {
  width: 100%;
  align-items: center;
  margin-top: $margin-top-large;

  &--container {
    overflow: hidden;
    font-family: var(--main-font-primary);
    // margin: 0 1.7rem 0 0;
    margin: 0;

    @media only screen and (max-width: 750px) {
      margin: $title-area-padding;
    }
  }

  &__select {
    border: solid 1px #c3d3d4;
    border-radius: $border-radius;
    padding-right: 1rem;
    position: relative;
    background-color: $main-white;
    margin-bottom: 1.6rem;
    select {
      border-radius: $border-radius;
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background-color: white;
      // background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-image: url("data:image/svg+xml;utf8,<svg fill='%23a19aa8' height='20' width='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><polygon points='12 17.414 3.293 8.707 4.707 7.293 12 14.586 19.293 7.293 20.707 8.707 12 17.414'/></svg>");
      background-position: right center;
      background-repeat: no-repeat;
      cursor: pointer;
      /* --------- */
      background-color: $main-white;
      height: $input-height;
      padding: $input-padding;
      padding-top: 1.5rem;
      transition: $normal-transition;
      outline: none;
      border: none;
      color: $main-black;
      font-size: $input-font-size;
      width: 100%;
      padding-right: 2rem;
    }
    label {
      position: absolute;
      font-weight: 200 !important;
      color: $placeholder-color;
      font-size: 1.2rem;
      margin-top: 0.7rem;
      padding: $input-padding;
      top: 0;
      left: 0.01rem;
    }
  }
}
