.mainProductImage {
  .swiper {
    padding-top: 1.3rem !important;
  }

  &[data-swiper-img-length-high="true"] {
    .swiper-button-lock {
      display: flex !important;
    }
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      pointer-events: visible !important;
      opacity: 1 !important;
      cursor: pointer !important;
    }
  }
}

.product-images-slider {
  border: solid 1px #c3d3d4;
  border-radius: 0.7rem;
  background-color: #fff;
  padding-top: 2rem;
  overflow: hidden;

  .swiper-slide {
    padding-top: 70%;
    overflow: hidden;
    position: relative;
    background-color: #fff;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      // background-color: #dddddd53;
      background-color: #fff;
      border-radius: 0.5rem;
    }
  }

  &--placeholder {
    position: relative;
    width: 100%;
    filter: blur(10px);
    // background: #dddddd53;
    background-color: #fff;
    transform: scale(1);
    animation: pulsate 700ms alternate infinite ease;
  }

  .swiper-button-prev {
    left: 30;
    // color: rgb(255, 255, 255);
  }

  .swiper-button-next {
    right: 30;
    // color: rgb(255, 255, 255);
  }

  .swiper-button-prev::after {
    color: var(--theme-color);
    text-align: center;
    font-size: 2rem !important;
    padding-right: 0.5rem;
  }

  .swiper-button-next::after {
    color: var(--theme-color);
    text-align: center;
    font-size: 2rem !important;
    padding-left: 0.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-color: #a3a3a323;
  }
}

.product-images-slider-thumbs {
  margin-top: 1rem;
  .swiper-slide {
    cursor: pointer;
    border: solid 1px #c3d3d4;
    /* height: 7rem;
    width: 7rem; */
    border-radius: 0.5rem;
    // background-color: #dddddd53;
    background-color: #fff;

    img {
      filter: opacity(0.6);
      -webkit-filter: opacity(0.6);
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-thumb-active {
      border: 1px solid #1890ff;

      img {
        filter: opacity(1);
        -webkit-filter: opacity(1);
      }
    }
  }

  &-wrapper {
    width: 100%;
    padding-top: 65%;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
