$cart-item-border-radius: 0.7rem;

.cart-item-container {
  position: relative;
  display: flex;
  gap: 2rem;

  .fake-checkbox {
    margin-top: 4.5rem;
    top: 4.5rem;

    input {
      height: 1.7rem !important;
      width: 1.7rem !important;
      cursor: pointer !important;

      /* &[type="checkbox"] {
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: 1px solid $main-blue;
        border-radius: 0.2rem;
        transition: $normal-transition;

        &:hover {
          border: 1px solid $main-blue-hover;
        }
      }

      &[type="checkbox"]:checked {
        -moz-appearance: auto;
        -webkit-appearance: auto;
        -o-appearance: auto;
        appearance: auto;
      } */

      @media only screen and (max-width: 600px) {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  .fake-checkbox-disabled {
    opacity: 0.5;
  }
}

.cart-item {
  font-family: var(--main-font-primary);
  width: clamp(20rem, 20rem, 90%);
  display: flex;
  flex-direction: row;
  gap: 1.8em;
  align-items: center;
  position: relative;
  cursor: pointer;
  /* min-height: 8rem; */
  /* padding: 0.7rem 0rem; */

  .cart-item-inner {
    height: 12rem;
    overflow: hidden;
    /* position: absolute; */
    object-fit: cover;
    &--placeholder {
      margin: 15px;
      filter: blur(10px);
      background: #dddddd53;
      transform: scale(1);
      animation: pulsate 700ms alternate infinite ease;
    }
  }

  .price-calc-holder {
    display: flex;
    gap: 0.375rem;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: xx-small;
    color: $main-black;
  }

  .low-stock-holder {
    margin-right: 4rem;
    margin-bottom: -0.7rem;
    display: flex;
    gap: 0.375rem;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: xx-small;
    color: $main-black;
  }

  svg {
    position: absolute;
    right: -4rem;
    top: 0;
    max-height: 2.6rem;
  }

  span.input-amount-dial-holder {
    position: absolute;
    /* right: 1.5rem;
    top: 0.583rem; */
    top: 50%;
    left: 93%;
    transform: translate(-50%, -50%);
    .input-amount-with-controls {
      width: max-content;
      gap: 1rem;
      button {
        margin: 0 !important;
        aspect-ratio: 1/1;
        font-size: 1.74rem;
      }
    }
  }

  .cart-item-total-holder {
    font-weight: 500 !important;
    font-size: 1.9rem;
    /* position: absolute; */
    bottom: 1rem;
    color: $main-black;
  }

  img {
    border-radius: $border-radius 0 0 $border-radius;
    // width: 10rem;
    // object-fit: cover; //remove if you want full image
    max-height: 13rem;
    object-fit: contain;
    aspect-ratio: 1/1;
  }

  .cart-item-details {
    display: flex;
    align-items: flex-start;
    font-size: 1.4rem;
    min-height: 8rem;
    gap: 0.5rem;
    padding: 0.7rem 0.5rem;

    .cart-item-name {
      margin-top: 0.5em;
      font-weight: 500 !important;
      padding-right: 50px;
      // color: black;
      color: var(--main-dark-font-color);
    }

    span.cart-item-amount-container {
      flex-grow: 1;
    }
  }
  .button-remove-cart-item {
    // color: black;
    color: var(--main-dark-font-color);
    border-radius: 100%;
    border: none;
    background-color: white;
    max-width: 2.4rem;
    max-height: 2.4rem;
    padding: 0;
    margin: 0;
    position: absolute;
    // right: calc(-1 * (100vw - 100%) + 40px);
    top: calc(- (100vh - 50%));
    right: 1rem;
    background-color: white;
    display: flex;
  }

  hr {
    position: absolute;
    height: 1px;
    width: calc(100vw - 3.6rem);
    background-color: #f0f0f0;
    bottom: -2rem;
    border: none;
  }
}

.relative-remove-button-holder {
  button {
    position: absolute;
    top: 4.8rem !important;
    right: 2.2rem !important;
  }
}

.cart-view .cart-item {
  position: absolute;
  border-radius: $border-radius;
  // box-shadow: var(--box-shadow-gray-light);
  filter: drop-shadow(0 0.25rem 0.25rem #80808080);
  -webkit-filter: drop-shadow(0 0.25rem 0.25rem #80808080);
  // -webkit-box-shadow: var(--box-shadow-gray-light);
  background-color: white !important;
  border: none;
  box-sizing: content-box;
}
