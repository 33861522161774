@import "../../abstracts/variables";

.theme1-variantSelector {
  width: 100%;
  align-items: center;
  margin-top: $margin-top-large;

  h2 {
    font-weight: 500 !important;
    color: #0b0f0e !important;
    font-size: 1.6rem !important;
    margin-bottom: 0;
  }

  &--container {
    font-family: var(--main-font-primary);

    margin: 0 1.7rem 0 0;
    display: flex;

    @media only screen and (max-width: 750px) {
      margin: $title-area-padding;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__select {
    margin-left: 1.5rem;

    label {
      font-weight: 500 !important;
      color: #0b0f0e;
      font-size: 1.3rem;
    }

    &-container {
      // border: solid 1px #c3d3d4;
      // padding: 1rem;
      // background-color: $main-white;
      // border-radius: 0.5rem;
      // margin-top: 1rem;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-position: right center;
      background-repeat: no-repeat;
      cursor: pointer;
      /* --------- */
      background-color: var(--theme-color);
      /* height: $input-height; */
      padding: 1rem 1.5rem;
      transition: $normal-transition;
      outline: none;
      border: none;
      font-size: 1.2rem;
      width: 100%;
      padding-right: 3.5rem;
      color: #fff;
      border-radius: 0.4rem;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
