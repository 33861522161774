@import "../abstracts/variables";

.header-hidden {
  height: 6rem;
  width: 100%;
  color: transparent;
  display: none;

  &[data-is-header-fixed="fixed"] {
    display: block;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $nav-back-color;
  height: 6rem;
  width: 100%;
  padding: 18px;
  color: $nav-font-color;
  position: relative;

  &[data-is-header-fixed="fixed"] {
    position: fixed;
    z-index: 99;
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;
  }

  &-right {
    .compactCart {
      margin-left: 1.5rem;
    }
  }

  .header-text-container-middle {
    display: none !important;
  }

  .header-text-container-left {
    display: block !important;
  }

  .header-empty-div {
    display: block !important;
  }

  @media only screen and (max-width: 600px) {
    .header-text-container-middle {
      display: block !important;
    }

    .header-text-container-left {
      display: none !important;
    }

    .header-empty-div {
      display: none !important;
    }
  }

  /*  &-profile {
    margin-right: 2rem !important;
  } */

  &-max-width-container {
    position: relative;
    width: 100%;
    max-width: 120rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &[data-is-cart="false"] {
      justify-content: flex-start;
      justify-content: space-between;
      @media only screen and (max-width: 600px) {
        justify-content: space-between;
      }
    }

    @media only screen and (max-width: 1200px) {
      max-width: 100%;
    }
  }

  .img__container {
    height: 3rem;
    width: 3rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    @media only screen and (max-width: 1200px) {
      margin-right: 0rem;
      margin-left: 0rem;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    svg {
      height: 100%;
      width: 100%;
      fill: $nav-font-color;
    }
  }

  h1 {
    font-family: var(--main-font-primary);
    font-weight: 600 !important;
    text-transform: uppercase;
  }

  .text__container {
    margin-left: 1rem;

    span {
      font-size: 1.1rem;
    }
    p {
      font-size: 1.3rem;
      font-family: var(--main-font-secondary) !important;
    }
  }

  .text__container[data-header-logo="false"] {
    left: 2rem;
  }
}
