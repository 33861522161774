@import "../abstracts/variables";

.orderCartSummaryCompact {
  background-color: white;
  // color: #2a2a2a;
  color: var(--main-dark-font-color);
  font-weight: 100 !important;
  position: relative;
  width: 100%;
  border-radius: $border-radius;
  border: solid 1px #c3d3d4;
  box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;

  &__left {
    display: flex;
    margin-left: 2rem;
    h5 {
      font-family: var(--main-font-primary);
      font-size: 1.6rem;
      font-weight: 500 !important;
      margin-block-start: 0.65em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      color: var(--main-dark-font-color);
      font-weight: 500 !important;
      margin-top: 0;
      margin-bottom: 0;

      &:last-child {
        margin-left: 1rem;
      }
    }

    .skeleton-loader {
      font-size: 1.6rem;
      margin-left: 2%;
      font-weight: 500 !important;
      position: absolute !important;
      max-width: 15%;
    }
  }

  &__right {
    margin-right: 1.5rem;
  }
}
