@import "../../abstracts/variables";

.linkBannerSC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $title-area-padding;
  margin: $section-margin;
  height: 3rem;
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    img {
      transform: rotate(180deg);
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    h1 {
      font-size: 1.9rem;
      font-weight: 700 !important;
      color: $main-black;
      margin: 0rem;
      margin-bottom: 0.32rem;
      margin-right: 1rem;
    }
  }

  &__right {
  }
}
