// Abstract files
@import "abstracts/all";

// Core files
@import "core/all";

// Components
@import "components/all";

// Layout
// @import "layouts/all";

// Sections
@import "sections/all";

// Pages
@import "pages/all";

@font-face {
  font-family: "Lufga";
  src: url("../fonts/Lufga-Medium.woff2") format("woff2"),
    url("../fonts/Lufga-Medium.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "inter";
  src: url("../fonts/inter.woff") format("woff");
  font-weight: 80;
  font-style: normal;
  font-display: swap;
}
