.order-cart-summary {
  background-color: white;
  color: var(--main-dark-font-color);
  font-weight: 100 !important;
  position: relative;
  width: 100%;
  border-radius: $border-radius;
  border: solid 1px #c3d3d4;
  box-shadow: 0.1rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.05);

  .order-amounts {
    max-width: 100%;
    padding: 1.82em;
    padding-bottom: 0;
  }

  .order-amount-field {
    position: relative;
    width: 100%;
    font-size: 1.5rem;
    font-family: var(--main-font-primary);
    font-style: normal;
    font-weight: 400 !important;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: left;

    div {
      position: absolute;
      right: 0;
      top: 0;
      color: var(--main-dark-font-color);
    }

    .skeleton-loader {
      position: absolute !important;
      right: 0;
      top: 10%;
      max-width: 15%;
    }
  }

  .order-total-holder {
    display: flex;
    font-family: var(--main-font-primary);
    font-size: 1.6rem;
    font-weight: 500 !important;
    margin-block-start: 0.65em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: left;

    div {
      position: absolute;
      font-weight: 500 !important;
      right: 2rem;
      color: var(--main-dark-font-color);
    }

    .skeleton-loader {
      font-size: 1.6rem;
      right: 2rem;
      font-weight: 500 !important;
      position: absolute !important;
      max-width: 15%;
    }
  }

  /* hr {
    // border: solid 0.01px #d7d7d7;
    color: #d7d7d7;
    height: 1px;
    background-color: #d7d7d7;
    margin: 0;
  } */

  &-divider-line {
    height: 1.6rem;
    width: 100%;
    border: none;
    border-bottom: solid 1px #c3d3d4;
  }
}
