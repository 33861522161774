.heading--main {
  font-family: var(--main-font-primary);
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 3.3rem;
  letter-spacing: 0em;
  text-align: left;
}

.heading--subheading-regular {
  font-family: var(--main-font-primary);
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 3.3rem;
  letter-spacing: 0em;
  text-align: left;
}

.heading-with-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  h1 {
    font-family: var(--main-font-primary);
    font-size: 2.4rem;
  }

  button {
    border: none;
    background: none;
    position: absolute;
    top: 2.4rem;
    left: 0;
  }

  a {
    position: absolute;
    top: 2.4rem;
    left: 0;
  }
}

.heading-medium-bold {
  font-family: var(--main-font-primary);
  text-transform: uppercase;
  max-width: 80%;
}

.heading-and-back-chevron {
  display: flex;
  align-items: center;

  h1 {
    margin-left: 1rem;
  }
}

span.heading-regular,
h2.heading-2-regular {
  font-size: 1.7rem;
  font-weight: 400 !important;
  font-family: var(--main-font-primary) !important;
}
h2.heading-2-regular {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

span.heading-with-button-to-right {
  display: flex;
  justify-content: space-between;
  // align-items: self-end;
  align-items: self-end;
  width: 100%;

  h2 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    color: $main-black;
    font-size: 1.7rem;
    font-weight: 400 !important;
    font-family: var(--main-font-primary) !important;
  }

  button {
    background-color: #00000000;
    border: none;
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    margin-bottom: 0.4rem;

    img {
      margin: 0;
      padding: 0;
    }
  }
}

.headingWithRightButton__button--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;

  .round-button-with-edit {
    margin-left: 1rem;
  }
}
