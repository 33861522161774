.toast-container {
  position: fixed;
  font-size: 1.32rem;
  display: table-column;
  align-items: center;
  justify-content: center;
  top: 9rem;
  /* min-height: 10vh; */
  padding: 1rem;
  z-index: 1001;
  /* padding-left: 9vw; */
  font-family: var(--main-font-primary);
  /* max-width: clamp(10vw, 44rem, 80vw);
  width: clamp(10vw, 44rem, 80vw); */
  width: 38rem;
  top: 10%rem;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 390px) {
    width: 35rem;
  }
  @media only screen and (max-width: 375px) {
    width: 38rem;
  }
  @media only screen and (max-width: 318px) {
    width: 30rem;
  }
  @media only screen and (max-width: 255px) {
    width: 25rem;
  }

  .toast-body-ERROR {
    /* background-color: rgba(240, 20, 20, 0.158); */
    /* color: rgb(186, 0, 0); */
    background-color: $error-back;
    color: $error-text;
  }
  .toast-body-INFO {
    background-color: $info-back;
    color: $info-text;
  }
  .toast-body-SUCCESS {
    /* background-color: rgba(21, 255, 0, 0.189); */
    /* color: rgb(46, 144, 5); */
    background-color: $success-back;
    color: $success-text;
  }
}

.toast-body {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 1.2rem;
  border-radius: $border-radius;
  margin-bottom: 1vh;
  -webkit-box-shadow: 0px 7px 15px -11px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 7px 15px -11px rgba(0, 0, 0, 0.67);
  animation: fadeInFromTop 0.7s linear 0.08s 1;

  &-left {
    p {
      font-size: 1.7rem;
      font-weight: 600 !important;
      font-family: var(--main-font-secondary) !important;
      margin: 0;
    }
    span {
      font-weight: 400 !important;
      font-size: 1.5rem;
    }
  }
  &-right {
    margin-left: 1.5rem;
    img {
      height: 3rem;
    }
  }
}
