.storybook-button {
  font-weight: 700 !important;
  border: 0;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}
.storybook-button--primary {
  // color: black;
  color: var(--main-dark-font-color);
  /* background-color: #33fad8; */
  background-color: var(--theme-teal);
}
.storybook-button--secondary {
  color: #333;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.storybook-button--small {
  font-size: 1.2rem;
  padding: 1rem 1.6rem;
}
.storybook-button--medium {
  font-size: 1.4rem;
  padding: 1.1rem 2rem;
}
.storybook-button--large {
  font-size: 1.6rem;
  padding: 1.2rem 2.4rem;
}

.button--checkout {
  border-radius: 0;
}

.button--pay {
  background-color: black;
  color: white;
}

.button-theme-teal {
  cursor: pointer;
  // background-color: #2af3cc;
  background-color: var(--theme-teal);
  // color: black;
  color: var(--main-dark-font-color);
  font-size: 1.8rem;
  font-family: var(--main-font-primary);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: $border-radius;
  padding: 13px 0;
  position: relative;
  bottom: 0%;
  max-width: 335px;
  width: 100%;
  margin-top: 1rem;
  -webkit-box-shadow: 0rem 0.1rem 0.8rem -0.2rem var(--theme-teal);
  box-shadow: 0rem 0.1rem 0.8rem -0.2rem var(--theme-teal);
}

.button-black-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--main-font-primary);
  font-size: 1.8rem;
  min-height: 6rem;
  width: 100%;
  margin-bottom: var(--button-spacing);
  border-radius: var(--button-border-radius);
  color: white;
  background-color: black;
  border: none;
  box-shadow: var(--box-shadow-gray-light);
  max-width: 33.5rem;
}

.button-black-arrow:hover {
  gap: 5rem;
}

.logoutButton {
  cursor: pointer;
  svg {
    fill: $main-black;
    height: 2.5rem;
    width: 2.5rem;
  }
}
