.theme1 {
  /* max-width: 120rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   background-color: bisque;

  &__content {
    max-width: 120rem;
    width: 100%;
    display: flex;
    &--left {
      width: 50%;
      //   background-color: brown;
      // padding: 2rem 4rem;
      padding: 2rem;
      position: relative;
    }

    &--right {
      width: 50%;
      //   background-color: blueviolet;

      .fast-checkout-page {
        padding: 2rem;
      }

      .shipping-details {
        margin-top: 1.6rem;
      }

      .billing-details {
        margin-top: 0rem !important;
      }

      .main-payment-card-content-holder {
        width: 100%;
      }
    }
  }
}

.product_details_theme1 {
  @media only screen and (max-width: 750px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
