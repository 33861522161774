.user-profile-dashboard {
  --outer-padding-dashboard: 2rem;

  background-color: #f6f6f6;

  position: relative;
  box-sizing: border-box;

  .user-profile-dashboard-top {
    box-sizing: border-box;
    background-color: white;
    padding: var(--outer-padding-dashboard);
  }

  .back-arrow-link {
    position: absolute;
    left: 0;
  }

  .profile-image-holder {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 100%;
      box-shadow: var(--box-shadow-gray-light);
    }

    h1 {
      font-family: var(--main-font-primary);
    }
  }

  .btn-edit-profile {
    background-color: var(--theme-teal);
    border-radius: 3.8rem;
    width: auto;
    min-width: 14.7rem;
    height: 4rem;
    padding: 1em;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    svg {
      max-height: 2rem;
    }
  }

  .menu-segments {
    background-color: #f6f6f6;
    box-sizing: border-box;

    .menu-segment-main {
      margin-top: 0.12em;

      h2 {
        padding: 0.125em var(--outer-padding-dashboard);
        font-size: 1.6rem;
        color: #7e7e7e;
        font-family: var(--main-font-primary);
      }

      .menu-segment-submenu {
        font-size: 1.6rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 3rem;
        color: var(--main-dark-font-color);
        background-color: white;
        font-family: var(--main-font-primary);

        span {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin-left: 2rem;
            font-family: var(--main-font-secondary) !important;
          }
        }

        svg {
          max-height: 1.8rem;
          transition: 0.21s ease-in-out;
          min-height: 3rem;
        }
      }
    }
  }

  .logout-button-holder {
    position: absolute;
    bottom: 12rem;

    min-height: 6rem;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .logout-button {
      border: none;
      width: clamp(33.5rem, 33.5rem, 80%);
      font-size: 1.8rem;
      font-family: var(--main-font-primary);
      background-color: #ff615e;
      color: white;
      border-radius: $border-radius;
      min-height: 6rem;
      -webkit-box-shadow: 0rem 0.1rem 0.8rem -0.2rem #ff615e;
      box-shadow: 0rem 0.1rem 0.8rem -0.2rem #ff615e;
    }
  }

  .bottom-gap {
    min-height: 20rem;
    background-color: white;
  }
}

// .menu-segment-submenu:last-child {
//     transition: .21s ease-in-out;

// }

// .menu-segment-submenu:hover:last-child {
//         transform: translateX(3px);
//     }
