.address-details-holder {
  position: relative;

  .retracted-address-text {
    h2 {
      // font-weight: 250 !important;
      font-weight: 400 !important;
      font-size: 1.6rem !important;
      font-family: var(--main-font-primary) !important;
      margin: 0;
      padding-right: 7rem;
      // font-size: 1.6rem;
    }

    span {
      display: inline-block;
      color: var(--main-dark-font-color);
      font-family: var(--main-font-secondary) !important;
      font-weight: 400 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 30rem;
      white-space: nowrap;
      font-size: 1.2rem;
      margin-top: 0.3rem;
      padding-right: 7rem;
    }
  }

  .address-details-inner {
    position: relative !important;
  }

  button.retract-address-over {
    position: absolute;
    right: 1.4rem;
    top: 1.84rem;
    z-index: 4;
  }

  .address-details-expanded input {
    display: flex !important;
  }

  .address-details-expanded {
    animation: displayTextSlow ease 0.5s;
    padding-top: 5rem;
    background-color: white;
  }

  .address-details-expanded > * {
    animation: hiddenToShown ease 0.25s;

    .address-zip-code-and-house-no {
      width: 92%;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      gap: 8%;
      margin-top: 0.8rem;

      .house-input,
      .zip-code-input {
        width: 100% !important;
      }
    }
  }
}
div.address-form {
  position: relative;
  width: 100%;

  .expanded-address-fields-holder {
    gap: 1.6rem;
  }

  .address-fields-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    color: var(--main-dark-font-color);

    input {
      min-width: 0;
      // border: solid 1px grey !important;
    }

    label {
      background-color: white;
    }
  }
}

.form--address-details {
  // background-color: rgba(42, 243, 204, 0.15);
  border: solid 1px #c3d3d4;
  border-radius: $border-radius;
  // padding: 18px;
  font-family: var(--main-font-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.6rem;
  margin-right: 0 !important;
  position: relative;
  background-color: white;

  &.address-details-expanded-selected {
    border: solid 1px #1890ff;
    overflow: hidden;
  }

  .retracted-address-text {
    border-bottom: #c3d3d4 solid 1px;
    background-color: rgb(220, 236, 255);
    padding: 1.1rem;

    h2 {
      font-weight: 400 !important;
      font-size: 1.6rem !important;
      font-family: var(--main-font-primary);
      margin: 0;
      /* font-size: 1.6rem; */
    }

    span {
      display: inline-block;
      color: var(--main-dark-font-color);
      font-family: var(--main-font-primary);
      font-weight: 400 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 30rem;
      white-space: nowrap;
      font-size: 1.2rem;
      margin-top: 0.3rem;
    }
  }

  a.detect-location {
    float: right;
    color: #0a90db;
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p.phone-info {
    color: #424d59;
    font-size: 1.3rem;
    display: flex;
  }

  input[type="text"] {
    padding: 1.6rem;
    border: solid 1px #c3d3d4;
    border-radius: $border-radius;
    letter-spacing: 0.05em;
    font-size: 1.6rem;
  }

  .google-map-holder {
    // add an appear animation here
    width: 90vw !important;
    margin: 0;
    padding: 0;
    border-radius: $border-radius;
    border: solid 1px var(--theme-teal) !important;
    overflow: hidden;
  }

  .save-button-holder {
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .save-address-button {
    cursor: pointer;
    background-color: var(--theme-teal);
    color: var(--main-dark-font-color);
    font-size: 1.8rem;
    font-family: var(--main-font-primary);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    border: none;
    border-radius: $border-radius;
    padding: 1.3rem 0;
    position: relative;
    bottom: 0%;
    max-width: 33.5rem;
    width: clamp(100%, 35rem, 90vw);
    margin-top: 1rem;
    -webkit-box-shadow: 0px 1px 8px -2px var(--theme-teal);
    box-shadow: 0px 1px 8px -2px var(--theme-teal);
  }
}

.unselected-address-form {
  padding: 0;
  margin: 0;
}

.address-details-retracted {
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.1rem;
  box-sizing: border-box;
  margin-bottom: 1.6rem;
  border: solid 1px #c3d3d4;
  background-color: white;
  // animation: displayTextSlow ease 3s;

  h2 {
    font-weight: 250 !important;
    font-family: var(--main-font-primary);
    margin: 0;
    font-size: 1.6rem;
  }

  span {
    display: inline-block;
    color: var(--main-dark-font-color);
    font-family: var(--main-font-primary);
    font-weight: 200 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 30rem;
    white-space: nowrap;
    font-size: 1.2rem;
  }

  button.edit-button,
  button.delete-button {
    height: 3.7rem !important;
    width: 3.7rem !important;
    img {
      height: 3.5rem !important;
      width: 3.5rem !important;
    }
  }

  button.edit-button {
    position: absolute;
    right: 5.1rem;
    top: 21%;
    background: none;
    width: min-content;
    border: none;
    /* transform: translate(190%, -50%); */
  }

  button.delete-button {
    position: absolute;
    right: 1rem;
    top: 21%;
    background: none;
    width: min-content;
    border: none;
    /* transform: translate(277%, -50%); */
  }

  &.selected-address-details-retracted {
    border: solid 1px #1890ff;
    background-color: rgb(220, 236, 255);
  }
}

button.retract-address-over {
  background: none;
  width: min-content;
  border: none;
}

.retract-rightside-up {
  animation: rotateArrowDown 0.3s forwards;
}

.retract-upside-down {
  animation: rotateArrowUp 0.3s forwards;
}

@keyframes rotateArrowDown {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes rotateArrowUp {
  from {
    transform: 0;
  }

  to {
    transform: rotate(180deg);
  }
}

.selected-address-retracted {
  border: solid 1px var(--main-teal-color);
  // background-color: rgba(42, 243, 204, 0.15);
  position: relative;
}

@media screen and (max-width: 390px) {
  .address-details-retracted span {
    width: 20rem;
  }
}

.unselected-address-form {
  background-color: white;
  border: none !important;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  height: auto !important;

  .address-holder-name-input {
    border: solid 1px #c3d3d4 !important;
    margin-bottom: 1.1rem;
  }

  .google-map-holder {
    min-width: 100%;
  }
}

.address-zip-code-and-house {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 1.6rem;

  .form-group {
    width: 100%;
    .form-input {
      min-width: 100%;
      width: 100%;
      padding: 0;
      height: 5.25rem;
    }
  }

  .house-input,
  .zip-code-input {
    width: 100% !important;

    input {
      padding-left: 2rem !important;
      padding-top: 0;
      box-sizing: border-box;
    }
  }
}

.address-details-theme-teal {
  .address-form,
  input,
  * {
    background-color: #00000000;
    // color: teal;
  }

  label {
    background-color: #e5fef8;
    // color: teal;
  }

  .form--address-details input[type="text"] {
    border: solid 1px var(--theme-teal);
  }
}

.address-details-inner-main {
  margin-bottom: 1.6rem;
}

.edit-address-buttons-container {
  position: absolute;
  right: 0.3rem;
  top: 4.5%;
  display: flex;
  align-items: center;
  justify-content: first baseline;
  padding-right: 0.2rem;
  gap: 1rem;

  button {
    margin: 0;
    padding: 0;
    height: 3.5rem;
    position: relative;
    z-index: 5;
    background-color: transparent;
    border: none;
    /* top: 0.8rem; */
    margin-right: 1rem;
    right: -0.5rem;
  }

  /* .tick-button,
  .cross-button {
    top: -0.2rem;
    right: 0.4rem;
  } */
}

.expanded-address-details-fields {
  .manual-fields-container {
    .manual-address-field {
      input,
      select {
        /* padding-left: 1.1rem; */
        // padding: 2rem 1.1rem 1.1rem 1.1rem;
        color: var(--main-dark-font-color);
        /* padding-top: 0.5rem; */
      }
      input {
        padding: 3rem 1.1rem 1.1rem 1.1rem;
      }
      select {
        // padding: 2rem 3rem 1.1rem 1.1rem;
      }
    }

    .manual-address-field:last-of-type {
      input,
      select div {
        border-radius: 0 0 $border-radius $border-radius;
        border-bottom: none;
      }
    }
  }

  /* .manual-fields-container:last-child{} */
}
