@import "../../abstracts/variables";

.introBannerSC {
  // height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $main-white;
  padding: 1.5rem 2rem;
  &__left {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 2rem;
    width: auto;
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }

  &__right {
    h3 {
      color: $main-black;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 1.4rem;
      font-weight: 500 !important;
      font-family: var(--main-font-primary) !important;

      @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
      }
    }
    p {
      color: $main-black;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 1.3rem;
      font-weight: 400 !important;
      font-family: var(--main-font-secondary) !important;
      @media only screen and (max-width: 600px) {
        font-size: 1.1rem;
      }
    }

    span {
      color: $main-black;
      margin-bottom: 0;
      font-size: 1.4rem;
      font-weight: 800 !important;
      @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
      }
    }
  }
}
