@import "../../abstracts/variables";

.theme2-order-cart-summary {
  background-color: white;
  // color: #2a2a2a;
  color: var(--main-dark-font-color);
  font-weight: 100 !important;
  position: relative;
  width: 100%;
  border-radius: $normal-border-radius;

  .theme2-order-amounts {
    max-width: 100%;
    padding: 1rem 1.82em;
  }

  .theme2-order-amount-field {
    position: relative;
    width: 100%;
    font-size: 1.5rem;
    font-family: var(--main-font-primary);
    font-style: normal;
    font-weight: 400 !important;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0.5rem;

    div {
      position: absolute;
      right: 0;
      top: 0;
      // color: black;
      color: var(--main-dark-font-color);
    }

    .skeleton-loader {
      position: absolute;
      right: 0;
      top: 10%;
      max-width: 15%;
    }
  }

  .theme2-order-total-holder {
    display: flex;
    font-family: var(--main-font-primary);

    h5 {
      font-size: 1.4rem;
      font-weight: 500 !important;
      margin: 0;
      margin-top: 2rem;
      //   margin-bottom: 2rem;
    }
  }

  .theme2-order-total-holder h5:last-of-type {
    position: absolute;
    right: 2rem;
    // color: black;
    color: var(--main-dark-font-color);
    font-weight: 500 !important;
    font-size: 1.6rem;
  }

  .theme2-order-summary-heading {
    font-family: var(--main-font-primary);

    h5 {
      font-size: 1.6rem;
      font-weight: 500 !important;
      margin: 0;
      margin-bottom: 1rem;
    }
  }
}
