@import "../abstracts/variables";

.toggleInputTheme1 {
  border: 1px solid #1890ff;
  background-color: #fff;
  border-radius: 2rem;

  .toggleInputTheme1-container {
    cursor: pointer;
    height: 2.5rem;
    width: 5rem;
    position: relative;
    border-radius: 2rem;

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
    }

    .toggleInputTheme1-indicator-svg {
      position: absolute;
      z-index: 1;
      height: 1.5rem;
      width: 1.5rem;
      left: 0.5rem;
      top: 0.5rem;
      fill: #fff;
    }

    .toggleInputTheme1-indicator-circle {
      transition: all 0.3s ease-in-out;
      position: absolute;
      z-index: 2;
      height: 2rem;
      width: 2rem;
      background-color: #1890ff;
      border-radius: 50%;
      left: 0.25rem;
      top: 0.24rem;
    }
  }

  &[data-toggle-input-on="true"] {
    background-color: #1890ff;
    .toggleInputTheme1-indicator-circle {
      left: 2.75rem;
      background-color: #fff;
    }
  }
}
