@import "../../abstracts/variables";

.cartOperatorTheme2 {
  padding: 1.75rem 0rem;
  //   margin-top: 1.75rem;
  border-top: 2px solid $main-light-gray;
  h2 {
    margin: 0;
    font-family: var(--main-font-primary);
    color: var(--main-dark-font-color);
    font-size: 1.75rem !important;
    font-weight: 400 !important;
    margin-bottom: 1.75rem;
  }

  &__top {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    button {
      border-radius: $border-radius;
      background-color: $background-grey;
      height: 2.5rem;
      width: 2.5rem;
      color: var(--main-dark-font-color);
      border: none;
    }

    &--minusBtn,
    &--plusBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    &--minusBtn {
      margin-right: 1rem;
    }
    &--plusBtn {
      margin-left: 1rem;
    }
    p {
      font-size: 1.6rem;
      font-weight: 500 !important;
      color: $input-color;
      font-family: var(--main-font-secondary) !important;
      margin: 0;
    }
  }

  &__info {
    font-family: var(--main-font-primary);
    color: $placeholder-color;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  &__bottom {
    border-top: 2px solid $main-light-gray;
    padding-top: 2rem;
    button {
      font-family: var(--main-font-primary);
      color: var(--main-dark-font-color);
      border: 1px solid var(--pay-button-color);
      border-radius: 0.7rem;
      padding: 1rem 2rem;
      width: 100%;
      font-size: 1.4rem;
      font-weight: 500 !important;
      background-color: $main-white;
    }
  }
}
