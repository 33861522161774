.account-form {
  font-family: var(--main-font-primary);
  display: flex;
  flex-direction: column;
  // margin-top: -1.8rem;
  margin-top: 0;
  /* gap: 16px; */

  h1 {
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .not-you-prompt-button {
    background-color: white;
    border: solid 1px #818181;
    color: #818181;
    font-size: 1.4rem;
    padding: 0.4rem 0.6rem;
    border-radius: 0.3rem;
  }

  .account-heading-holder {
    display: flex;
    width: 100%;
    min-width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  span {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    box-sizing: border-box;

    b {
      text-align: left;
      font-size: 1.6rem;
      margin-bottom: 0.9rem;
    }

    input {
      padding: 2.5rem 1.6rem 1.6rem 1.6rem;
      // letter-spacing: 0.05em;
      font-size: 1.7rem;
      color: var(--main-dark-font-color);
    }
  }

  &[data-account-form-reverse-is-email-readonly="true"] {
    span {
      flex-direction: column-reverse;
    }
  }
}

.account-phone-heading-holder {
  display: flex;
  width: 100%;
  min-width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: var(--main-font-primary) !important;
  /* margin-top: 2rem; */

  @media only screen and (max-width: 750px) {
    margin-top: 2rem;
  }

  &__left {
    display: flex;
    align-items: center;
    svg {
      fill: $main-black;
      height: 2rem;
      width: 2rem;
      margin-right: 1rem;
    }
    h2 {
      color: $main-black;
      font-size: 1.7rem;
      font-weight: 400 !important;
      font-family: var(--main-font-primary);
    }
  }
  &__right {
    display: flex;
    align-items: center;
    /* margin-top: 0.8rem; */

    &--profile {
      margin-top: 0.2rem;
      svg {
        fill: $main-black;
        height: 2.5rem;
        width: 2.5rem;
      }
    }
    &--logout {
      margin-left: 1rem;
      margin-top: 0.2rem;
    }
  }
}

.account-form-compact {
  display: flex;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }

  &-left {
    width: 50%;
    margin-right: 1rem;
    @media only screen and (max-width: 950px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &-right {
    width: 50%;
    margin-left: 1rem;
    @media only screen and (max-width: 950px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
