.switchButton {
  margin-right: 1rem;
  .switch {
    position: relative;
    display: inline-block;
    width: 4.2rem;
    height: 2.2rem;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //   background-color: #ccc;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 1.8rem;
      width: 1.8rem;
      left: 0.2rem;
      bottom: 0.2rem;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      //   box-shadow: 0 0 1px #2196f3;
      box-shadow: 0 0 0px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(2rem);
      -ms-transform: translateX(2rem);
      transform: translateX(2rem);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 3rem;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
