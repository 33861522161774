.view-all-link-holder {
  z-index: 5;
  transform: translateY(-8rem);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-end;
  position: absolute;
  left: 0%;

  .view-all-link {
    margin-top: 1rem;
    color: gray;
    font-family: var(--main-font-primary);
    font-size: 1.6rem;
    width: 100vw;
    left: 0;
    text-align: center;

    /*  Linear gradient */
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 27%,
      rgba(255, 255, 255, 0) 93%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 27%,
      rgba(255, 255, 255, 0) 93%
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 27%,
      rgba(255, 255, 255, 0) 93%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    bottom: 0;
    height: 8rem;

    a {
      position: absolute;
      left: 0;
      bottom: 1rem;
      width: 100%;
      text-align: center;
    }
  }
}
