.billing-is-shipping {
  font-family: var(--main-font-primary);

  &-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    font-size: 1.4rem;

    img {
      /* margin-top: 0.5rem; */
      cursor: pointer;
    }

    /* input {
      height: 1.8rem !important;
      width: 1.8rem !important;
      cursor: pointer !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      outline: 0.5px solid $dark-icon-color;
      border-radius: 0.2rem;
      background-color: white;
      -webkit-box-shadow: 0px 0px 4px 1px #076fd8;
      box-shadow: 0px 0px 4px 1px #076fd8;

      &:checked {
        background: white url("../../assets/svg/tick.svg") no-repeat;
        background-size: 1.8rem 1.8rem;
        background-position: 50% 50%;
      }
    } */

    input {
      height: 1.8rem !important;
      width: 1.8rem !important;
      cursor: pointer !important;
      margin: 0 !important;
    }

    &-text {
      margin-bottom: 0.12rem;
    }
  }
}
