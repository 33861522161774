.manual-address-field-select {
  // padding-right: 1.1rem;
}

.manual-address-field {
  // padding-top: 0.2rem;
  input,
  select {
    letter-spacing: 0.05em;
    color: var(--main-dark-font-color);
    border-bottom: solid 0.1px #e0eced;
    transition: all 0.3s ease;
    border-radius: 0 !important;
  }

  input {
    padding-top: 1.4rem;
    padding-left: 1.1rem;
    font-size: 1.6rem;
  }

  select {
    padding-top: 0.8rem;
    padding-left: 1.1rem;
    padding-bottom: 0.8rem;
    font-size: 1.7rem;
    font-weight: 400;
    border-radius: 0 !important;
    /* --------------------- */
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23a19aa8' height='20' width='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><polygon points='12 17.414 3.293 8.707 4.707 7.293 12 14.586 19.293 7.293 20.707 8.707 12 17.414'/></svg>");
    //background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-position: calc(100% - 1.8rem) center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .addressCountrySelector-option {
    color: var(--main-dark-font-color) !important;
  }

  input:focus,
  select:focus {
    border-bottom: solid 1px #1890ff !important;
    color: rgba(0, 0, 0, 0.4);
  }

  .manual-address-field-label {
    position: absolute;
    font-weight: 200 !important;
    color: $placeholder-color;
    font-size: $input-font-size;
    pointer-events: none;
    // top: 2rem;
    // top: 0.5rem !important;
    top: 0.2rem !important;
    transition: all 300ms ease-in-out;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }
}
